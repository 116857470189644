

// import { Container } from "react-bootstrap";
// import ProductCard from "./ProductCard/ProductCard";
// import './Section.css'; // Optional if needed


// const Section = ({ title, bgColor, productItems }) => {
//   return (
//     <section style={{ background: bgColor }}>
//       <Container>
// <div className="heading text-center mt-4 justify-center">
//       {productItems?.length ==0 ? (
//         // Loader while fetching data
//         <div className="flex items-center justify-center space-x-2">
//           <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce"></div>
//           <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce delay-200"></div>
//           <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce delay-400"></div>
//         </div>
//       ) : productItems?.length > 0 ? (
//         // Show title when data is loaded
//         <h1 className="text-xl font-bold text-gray-800">{title}</h1>
//       ) : (
//         // Display message when no data matches
//         <h1 className="text-xl font-bold text-red-500">No match found</h1>
//       )}
//     </div>
        
//         {/* Tailwind Grid Responsive Design */}
//         <div 
//         className="grid-container"
//         >
//           <div 
//           className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4"
//           >
//             {productItems?.map((productItem) => (
//               <div key={productItem.id} className="product-item">
//                 <ProductCard
//                   title={title}
//                   productItem={productItem}
//                 />
//               </div>
//             ))}
//           </div>
//         </div>
//       </Container>
//     </section>
//   );
// };

// export default Section;






// import { useEffect, useState } from "react";
// import { Container } from "react-bootstrap";
// import ProductCard from "./ProductCard/ProductCard";
// import './Section.css';

// const Section = ({ title, bgColor, productItems }) => {
//   const [ads, setAds] = useState([]);
//   const [mergedItems, setMergedItems] = useState([]);
//   const [hiddenAds, setHiddenAds] = useState([]); // Store hidden ads

//   useEffect(() => {
//     const fetchAdvertisements = async () => {
//       try {
//         const response = await fetch("http://localhost:5023/api/electronics/advertisements");
//         const result = await response.json();
//         if (result.success) {
//           setAds(result.data);
//         }
//       } catch (error) {
//         console.error("Error fetching advertisements:", error);
//       }
//     };

//     fetchAdvertisements();
//   }, []);

//   useEffect(() => {
//     if (ads.length && productItems.length) {
//       // Merge ads into product items after every 5 products
//       const merged = [];
//       const adCycle = ads.length;
//       let adIndex = 0;

//       productItems.forEach((product, index) => {
//         merged.push(product);
//         if ((index + 1) % 5 === 0 && adIndex < adCycle) {
//           merged.push({ ...ads[adIndex], isAd: true, id: ads[adIndex].id }); // Mark ad for UI identification
//           adIndex = (adIndex + 1) % adCycle;
//         }
//       });

//       setMergedItems(merged);
//     } else {
//       setMergedItems(productItems);
//     }
//   }, [ads, productItems]);

//   const handleAdClose = (adId) => {
//     setHiddenAds((prev) => [...prev, adId]); // Add the adId to hiddenAds array
//   };

//   return (
//     <section style={{ background: bgColor }}>
//       <Container>
//         <div className="heading text-center mt-4 justify-center">
//           {productItems?.length === 0 ? (
//             <div className="flex items-center justify-center space-x-2">
//               <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce"></div>
//               <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce delay-200"></div>
//               <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce delay-400"></div>
//             </div>
//           ) : (
//             <h1 className="text-xl font-bold text-gray-800">{title}</h1>
//           )}
//         </div>

//         <div className="grid-container">
//           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
//             {mergedItems.map((item, index) => (
//               <div key={index} className="product-item">
//                 {item.isAd ? (
//                   // Check if the ad is in the hiddenAds array
//                   !hiddenAds.includes(item.id) && (
//                     <div className="ad-card relative">
//                       <button
//                         className="absolute top-0 right-0 p-2 text-white bg-gray-600 rounded-full"
//                         onClick={() => handleAdClose(item.id)} // Close the ad
//                       >
//                         X
//                       </button>
//                       <a href={item.targetUrl} target="_blank" rel="noopener noreferrer">
//                         <img
//                           src={item.imageUrl}
//                           alt={item.title}
//                           className="ad-image"
//                           style={{ width: "100%", height: "auto", objectFit: "cover" }}
//                         />
//                         <p className="ad-title text-center text-gray-500 mt-2">{item.title}</p>
//                       </a>
//                     </div>
//                   )
//                 ) : (
//                   // Render Product
//                   <ProductCard title={title} productItem={item} />
//                 )}
//               </div>
//             ))}
//           </div>
//         </div>
//       </Container>
//     </section>
//   );
// };

// export default Section;








// import { useEffect, useState } from "react";
// import { Container } from "react-bootstrap";
// import ProductCard from "./ProductCard/ProductCard";
// import './Section.css';

// const Section = ({ title, bgColor, productItems }) => {
//   const [ads, setAds] = useState([]);
//   const [mergedItems, setMergedItems] = useState([]);
//   const [hiddenAds, setHiddenAds] = useState([]); // Store hidden ads

//   useEffect(() => {
//     const fetchAdvertisements = async () => {
//       try {
//         const response = await fetch("http://localhost:5023/api/electronics/advertisements");
//         const result = await response.json();
//         if (result.success) {
//           setAds(result.data);
//         }
//       } catch (error) {
//         console.error("Error fetching advertisements:", error);
//       }
//     };

//     fetchAdvertisements();
//   }, []);

//   useEffect(() => {
//     if (ads.length && productItems.length) {
//       // Merge ads into product items after every 5 products
//       const merged = [];
//       const adCycle = ads.length;
//       let adIndex = 0;

//       productItems.forEach((product, index) => {
//         merged.push(product);
//         if ((index + 1) % 5 === 0 && adIndex < adCycle) {
//           merged.push({ ...ads[adIndex], isAd: true, id: ads[adIndex].id }); // Mark ad for UI identification
//           adIndex = (adIndex + 1) % adCycle;
//         }
//       });

//       setMergedItems(merged);
//     } else {
//       setMergedItems(productItems);
//     }
//   }, [ads, productItems]);

//   const handleAdClose = (adId) => {
//     setHiddenAds((prev) => [...prev, adId]); // Add the adId to hiddenAds array
//   };

//   return (
//     <section style={{ background: bgColor }}>
//       <Container>
//         <div className="heading text-center mt-4 justify-center">
//           {productItems?.length === 0 ? (
//             <div className="flex items-center justify-center space-x-2">
//               <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce"></div>
//               <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce delay-200"></div>
//               <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce delay-400"></div>
//             </div>
//           ) : (
//             <h1 className="text-xl font-bold text-gray-800">{title}</h1>
//           )}
//         </div>

//         <div className="grid-container">
//           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
//             {mergedItems.map((item, index) => (
//               <div key={index} className="product-item">
//                 {item.isAd ? (
//                   // Check if the ad is in the hiddenAds array
//                   !hiddenAds.includes(item.id) && (
//                     <div className="ad-card relative">
//                       <button
//                         className="absolute top-0 right-0 p-2 text-white bg-gray-600 rounded-full"
//                         onClick={() => handleAdClose(item.id)} // Close the ad
//                       >
//                         X
//                       </button>
//                       <a href={item.targetUrl} target="_blank" rel="noopener noreferrer">
//                         <img
//                           src={item.imageUrl}
//                           alt={item.title}
//                           className="ad-image"
//                           style={{ width: "100%", height: "auto", objectFit: "cover" }}
//                         />
//                         <p className="ad-title text-center text-gray-500 mt-2">{item.title}</p>
//                       </a>
//                     </div>
//                   )
//                 ) : (
//                   // Render Product
//                   <ProductCard title={title} productItem={item} />
//                 )}
//               </div>
//             ))}
//           </div>
//         </div>
//       </Container>
//     </section>
//   );
// };

// export default Section;






// import { useEffect, useState } from "react";
// import { Container } from "react-bootstrap";
// import ProductCard from "./ProductCard/ProductCard";
// import './Section.css';
// import { getAllAdvertisement } from "../utils/apiCall";

// const Section = ({ title, bgColor, productItems }) => {
//   const [ads, setAds] = useState([]);
//   const [mergedItems, setMergedItems] = useState([]);
//   const [hiddenAds, setHiddenAds] = useState([]); // Store hidden ads

//   useEffect(() => {
//     const fetchAdvertisements = async () => {
//       try {
//         const result = await getAllAdvertisement();
//         if (result.success) {
//           setAds(result.data);
//         }
//       } catch (error) {
//         console.error("Error fetching advertisements:", error);
//       }
//     };

//     fetchAdvertisements();
//   }, []);

//   useEffect(() => {
//     if (ads.length && productItems.length) {
//       // Merge ads into product items after every 5 products
//       const merged = [];
//       const adCycle = ads.length;
//       let adIndex = 0;

//       productItems.forEach((product, index) => {
//         merged.push(product);
//         if ((index + 1) % 5 === 0 && adIndex < adCycle) {
//           merged.push({ ...ads[adIndex], isAd: true, id: ads[adIndex].id }); // Mark ad for UI identification
//           adIndex = (adIndex + 1) % adCycle;
//         }
//       });

//       setMergedItems(merged);
//     } else {
//       setMergedItems(productItems);
//     }
//   }, [ads, productItems]);

//   const handleAdClose = (adId) => {
//     setHiddenAds((prev) => [...prev, adId]); // Add the adId to hiddenAds array
//     setMergedItems((prev) => prev.filter(item => item.id !== adId)); // Remove ad from merged items
//   };

//   return (
//     <section style={{ background: bgColor }}>
//       <Container>
//         <div className="heading text-center mt-4 justify-center">
//           {productItems?.length === 0 ? (
//             <div className="flex items-center justify-center space-x-2">
//               <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce"></div>
//               <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce delay-200"></div>
//               <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce delay-400"></div>
//             </div>
//           ) : (
//             <h1 className="text-xl font-bold text-gray-800">{title}</h1>
//           )}
//         </div>

//         <div className="grid-container">
//           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
//             {mergedItems.map((item, index) => (
//               <div key={index} className="product-item">
//                 {item.isAd ? (
//                   // Check if the ad is in the hiddenAds array
//                   !hiddenAds.includes(item.id) && (
//                     <div className="ad-card relative">
//                       <button
//                         className="absolute top-0 right-0 p-2 text-white bg-gray-600 rounded-full"
//                         onClick={() => handleAdClose(item.id)} // Close the ad
//                       >
//                         X
//                       </button>
//                       <a href={item.targetUrl} target="_blank" rel="noopener noreferrer">
//                         <img
//                           src={item.imageUrl}
//                           alt={item.title}
//                           className="ad-image"
//                           style={{ width: "100%", height: "auto", objectFit: "cover" }}
//                         />
//                         <p className="ad-title text-center text-gray-500 mt-2">{item.title}</p>
//                       </a>
//                     </div>
//                   )
//                 ) : (
//                   // Render Product
//                   <ProductCard title={title} productItem={item} />
//                 )}
//               </div>
//             ))}
//           </div>
//         </div>
//       </Container>
//     </section>
//   );
// };

// export default Section;






import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import ProductCard from "./ProductCard/ProductCard";
// import './Section.css';
import { getAllAdvertisement, storeImpression } from "../utils/apiCall";
import OTPVerification from "./HomePage/OtpVerification";
import CouponPopup from "./ProductCard/CouponPopup";


const Section = ({ title, bgColor, productItems }) => {
  const [ads, setAds] = useState([]);
  const [mergedItems, setMergedItems] = useState([]);
  const [hiddenAds, setHiddenAds] = useState([]); // Store hidden ads



    const [isModalVisible, setModalVisible] = useState(false);  // To control modal visibility

    // Close the modal
    const handleCloseModal = () => {
      setModalVisible(false);
    };
  
    const loginFunction=()=>{
      setModalVisible(true);
  }

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [couponData,setCoupon] = useState(null);
  const [discountPercent,setDiscountPercent] = useState(null);

  const handleOpenPopup = (coupon) => {
    console.log('coupon data',coupon)
    setCoupon(coupon)
    const discountPercent = coupon?.couponCode?.match(/\d+$/)?.[0];
    setDiscountPercent(discountPercent);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };


  useEffect(() => {
    const fetchAdvertisements = async () => {
      try {
        const result = await getAllAdvertisement();
        if (result.success) {
          setAds(result.data);
        }
      } catch (error) {
        console.error("Error fetching advertisements:", error);
      }
    };

    fetchAdvertisements();
  }, []);

  useEffect(() => {
    if (ads?.length && productItems?.length) {
      // Merge ads into product items after every 5 products
      const merged = [];
      const adCycle = ads.length;
      let adIndex = 0;

      productItems.forEach((product, index) => {
        merged.push(product);
        if ((index + 1) % 5 === 0 && adIndex < adCycle) {
          merged.push({ ...ads[adIndex], isAd: true, id: ads[adIndex].id }); // Mark ad for UI identification
          adIndex = (adIndex + 1) % adCycle;
        }
      });

      setMergedItems(merged);
    } else {
      setMergedItems(productItems);
    }
  }, [ads, productItems]);

  const handleAdClose = async (adId) => {
    // Collect device information
    const deviceInfo = {
      userAgent: navigator.userAgent, // Collects browser and OS information
      platform: navigator.platform,   // Collects the platform (e.g., Windows, Mac, etc.)
      language: navigator.language,   // Collects the language setting of the browser
      screenWidth: window.innerWidth, // Collects screen width
      screenHeight: window.innerHeight // Collects screen height
    };

    // Prepare the payload for tracking ad click
    const payload = {
      adId: adId,
      deviceInfo: deviceInfo
    };

    try {
      // Send POST request with ad click and device information
      const response =await storeImpression(payload,adId)
      if (response.status) {
        console.log('Ad click tracked successfully');
      } else {
        console.error('Failed to track ad click');
      }
    } catch (error) {
      console.error('Error tracking ad click:', error);
    }

    // Hide the ad after clicking
    setHiddenAds((prev) => [...prev, adId]); // Add the adId to hiddenAds array
    setMergedItems((prev) => prev.filter(item => item.id !== adId)); // Remove ad from merged items
  };

  return (
    // <section style={{ background: bgColor }}>
      
      // <Container>
        <div
        style={{ background: bgColor ,paddingLeft:10,paddingRight:10,marginTop:-40 }}
        >
          <div>
        <div className="heading text-center justify-center">
          {productItems?.length === 0 ? (
          <div className="flex items-center justify-center min-h-screen">
          <div className="flex items-center space-x-2">
            {/* <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce"></div>
            <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce delay-200"></div>
            <div className="w-4 h-4 bg-blue-500 rounded-full animate-bounce delay-400"></div> */}
            <h3 className="text-lg font-medium text-gray-700">Loading...</h3>
          </div>
        </div>
        
          ) : (
            <h1 className="text-xl font-bold text-gray-800">{title}</h1>
          )}
        </div>

        <div className="grid-container">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {mergedItems?.map((item, index) => (
              <div key={index} className="product-item">
                {item.isAd ? (
                  // Check if the ad is in the hiddenAds array
                  !hiddenAds.includes(item.id) && (
                    <div className=" relative"
                    onClick={() => handleAdClose(item.id)} 
                    >
                      <button
                        className="absolute top-0 right-0 p-2 text-white bg-gray-600 rounded-full z-40"
                        // onClick={() => handleAdClose(item.id)} // Close the ad
                        // className="absolute"
                      >
                        X
                      </button>
                      <a 
                      // href={item.targetUrl} 
                      // target="_blank" rel="noopener noreferrer"
                      >
                        <img
                        style={{height: 500}}
                          src={item.imageUrl}
                          alt={item.title}
                          className="product-card"
                          // style={{ width: "100%", height: "auto", objectFit: "cover" }}
                        />
                        <p className="ad-title text-center text-gray-500 mt-2">{item.title}</p>
                      </a>
                    </div>
                  )
                ) : (
                  // Render Product

                  <ProductCard 
                  title={title} 
                  productItem={item} 
                  onLogin={() =>loginFunction()}
                  showCoupon={(coupon) => handleOpenPopup(coupon)}
                  />
                )}
              </div>
            ))}
          </div>
        </div>

</div>
      {/* // </Container> */}
      <OTPVerification
      isOpen={isModalVisible} 
      onClose={handleCloseModal} 
       />
             {isPopupOpen && (
               <CouponPopup
                 couponCode={couponData.couponCode}
                 discountPercent={discountPercent}
                 validity="Coupon Code"
                 expiryDate={couponData.expiryDate}
                 onClose={handleClosePopup}
               />
             )}

    {/* // </section> */}
    </div>
  );
};

export default Section;



import React, { useState, useEffect } from "react";
import { axiosInstance } from "../utils/axios";
import { toast } from "react-toastify";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'; // Import the icons
import SelectDealerCategories from '../components/AdminDashboardComponent/SelectDealerCategories';
import axios from "axios";

import SuccessModal from '../components/SuccessModal';
import MyGoogleMap from "../components/user/myGoogleMap";
import ProductImageUpload from "../components/AdminDashboardComponent/RegisterPageComponents/CropImageModal";
import OtpVerificationModal from "../components/AdminDashboardComponent/RegisterPageComponents/otpVerificationModal";
import { validateFields } from "./validatefiled";
import { isDealerExistCheckFunction } from "../utils/apiCall";
import LocationSelect from "../components/AdminDashboardComponent/RegisterPageComponents/LocationSelect";


const RegistrationForm = () => {

  const [message,setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalToggle = () => {
    setIsModalOpen(!isModalOpen);
    if(isModalOpen){
      window.location.reload();
    }
  };

  const [selectedCategories, setSelectedCategories] = useState([]);

  console.log('selected categories in registration form ',selectedCategories);

  const [formData, setFormData] = useState({
    otpVerified:false,
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    password:"",
    shopName:"",
    shopAddress:"",
    gst: "",
    photo: null,
    frontImage:"",
    categoryIds : selectedCategories,
    city:'',
    state:'',
    area:'',
    photo1:'',
    photo2:'',
    photo3:'',
    photo4:'',
    postalCode:'',
    country:'',
    bankDetails: {
      bankName: "",
      accountNumber: "",
      accountHolderName: "",
      branch: "",
    },
    kyc: null,
    gstCertificate: null,
    location: { latitude: null, longitude: null }, // Store geolocation
  });

  useEffect(()=>{
    setFormData((prevData) => ({
      ...prevData,
      ['categoryIds']: selectedCategories,
    }));
  },[selectedCategories])

  const [isLoading, setIsLoading] = useState(false); // State to track form submission

  const [currentLocation, setCurrentLocation] = useState({ lat: null, lng: null });

  const handleLocationChange = (newLatLng) => {
    console.log("Latest LatLng: ", newLatLng); // Log for debugging

    setCurrentLocation(newLatLng);
    getlocationData();
  };



  const locationAllowFunction = async (value) => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Successfully got the position
            setFormData((prevData) => ({
              ...prevData,
              location: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
            }));
  
            console.log('Position:', position.coords.latitude, position.coords.longitude);
            // setIsLoading(false)
            // Resolve with latitude and longitude
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            setIsLoading(false)
            console.error("Error fetching geolocation:", error);
  
            if (error.code === 1) {
             
              // User denied location access
              alert("Location access denied. Please enable location services.");
            } else if (error.code === 2) {
              // Location unavailable (might be because it's turned off or device issue)
              alert("Location is unavailable. Please turn on location services.");
            } else {
              // Handle other errors
              // alert("An error occurred while fetching your location.");
            }
  
            reject(error); // Reject the promise with the error
          }
        );
      } else {
        setIsLoading(false)
        console.log("Geolocation is not supported by this browser.");
        alert("Geolocation is not supported by your browser.");
        reject("Geolocation is not supported");
      }
    });
  };
  


  const getlocationData =async()=>{
    // const { latitude, longitude } = await locationAllowFunction();
    const latitude = currentLocation.lat 
    const longitude = currentLocation.lng;
    if(currentLocation.lng){
      console.log(latitude,longitude);
        // Fetch location details based on the latitude and longitude
        axios
          .get(
            `https://api.opencagedata.com/geocode/v1/json?q=${currentLocation.lat }+${currentLocation.lng}&key=c45d8ebb7d6e434ba91531b92d08a14c&language=en&pretty=1`
          )
          .then((response) => {
            const data = response.data;
            if (data.results && data.results.length > 0) {
              const result = data.results[0];
              setFormData({
                shopAddress: result.formatted,
                city: result.components.city || "City not found",
                country: result.components.country || "Country not found",
                state: result.components.state || "State not found",
                postalCode: result.components.postcode || "Postcode not found",
              });
            } else {
              alert("Location not found");
            }
          })
          .catch((error) => {
            console.log("Error fetching location:", error);
            // alert("Error fetching location");
          })
          .finally(() => {
            // setLoading(false);
          });
    
}
  }


  useEffect(()=>{
    getlocationData();
  },[])
  
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }))  
  };


  const handleBlur = (e) => {
    const { name, value, type, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.toUpperCase()
    }))  
    // setFormData((prevData) => ({
    //   ...prevData,
    //   name: prevData.name.toUpperCase()
    // }));
  };


 
  

  

  const handleSubmit = async (e) => {  

    setIsLoading(true);   

      // locationAllowFunction('submit');

    //   const { latitude, longitude } = await locationAllowFunction();
    //   console.log('ati longi',latitude,longitude)
    //   if(!latitude && !longitude){
    //   alert('allow location is manadory ')
    //   setTimeout(()=>{
    //     setIsLoading(false);
    //   },2000);
    //   return
    // }

    // Ensure latitude and longitude are valid numbers
    const latitude1 = parseFloat(currentLocation.lat);
    const longitude1 = parseFloat(currentLocation.lng);

    if(!latitude1 && !longitude1){
        alert('allow location is manadory ')
        return
    }
    // console.log('latitude lngitde',latitude,longitude)
  
    // if (isNaN(latitude) || isNaN(longitude)) {
    //   alert('Invalid location coordinates.');
    //   return; // Prevent form submission if coordinates are invalid
    // }
  
    // Prepare FormData to send
    const formDataToSend = new FormData();
  
    for (let key in formData) {
      if (formData[key] && typeof formData[key] !== 'object') {
        formDataToSend.append(key, formData[key]);
      } else if (formData[key]) {
        // For nested objects like bankDetails, we loop through them too
        for (let subKey in formData[key]) {
          formDataToSend.append(`${key}[${subKey}]`, formData[key][subKey]);
        }
      }
    }
  
    // Manually add the location (with numeric values)
    formDataToSend.append('latitude', latitude1);
    formDataToSend.append('longitude', longitude1); 
    // Append files specifically
    if (formData.photo) formDataToSend.append('photo', formData.photo);
    if (formData.kyc) formDataToSend.append('kyc', formData.kyc);
    if (formData.gstCertificate) formDataToSend.append('gstCertificate', formData.gstCertificate);

    formDataToSend.append('photo1', formData.photo1);
    formDataToSend.append('photo2', formData.photo2);
    formDataToSend.append('photo3', formData.photo3);
    formDataToSend.append('photo4', formData.photo4);
    formDataToSend.append('frontImage',formData.frontImage)
    formDataToSend.append('otpVerified', formData.otpVerified);

  console.log('yaha tak aaya ',formDataToSend)
  console.log('main form data',formData)

    // formDataToSend.append('categoryIds',selectedCategories)
    formDataToSend.append('categoryIds', selectedCategories.join(','));

    try {
      const response = await axiosInstance.post('/register', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      if (response.data.status) {
        setMessage(response.data.message);
        modalToggle();
        setTimeout(()=>{
          setIsLoading(false);
        },2000);
        // navigate('/login')
        // toast.success(response.data.message);
      } else {
        toast.success('Registration failed. Please try again.');
        setTimeout(()=>{
          setIsLoading(false);
        },2000);
      }
      console.log('Response:', response.data);
    }  catch (error) {
      console.error('Error submitting form:', error);
      setTimeout(()=>{
        setIsLoading(false);
      },2000);
      // Check if error response has a message
      if (error.response && error.response.data && error.response.data.message) {
        // Show the message from backend error response
        // setAllowLocation(error?.response?.data?.location);
        alert(error.response.data.message);
      } else {
        // Default error message
        alert('An unexpected error occurred. Please try again.');
      }
    }finally {
      // setIsLoading(false); // Set loading to false after the submission is complete
    }
  };
   
  const [showPassword, setShowPassword] = useState(false);

   // Toggle password visibility
   const togglePassword = () => {
    setShowPassword(!showPassword);
  };


  const handleImageChange = (image, key) => {
    console.log('iamge and key',image,key)
    if(image === 'removed'){
      setFormData((prevData) => ({
        ...prevData,
        [key]: null,
      }));  
    }
    setFormData((prevData) => ({
      ...prevData,
      [key]: image,
    }));

  };
  

  const [showModal, setShowModal] = useState(false);

  const handleVerifyClick = async () => {

    const missingField = validateFields(formData);
    console.log('form data',formData)
    if (missingField) {
      alert(`Please fill the mandatory field: ${missingField}`);
      return;
    }
    if(formData['otpVerified']){
      handleSubmit();
      return
    }
     // Step 1: Check if the mobile number is already registered
     const isRegisteredResponse1 = await isDealerExistCheckFunction(formData.mobile); // Call API to check registration status.
     console.log('response',isRegisteredResponse1)
     if (isRegisteredResponse1.status) {
       alert(isRegisteredResponse1.message); // If already registered, show the error message
       return
     } else{
       if (formData?.mobile?.length === 10) {
        setShowModal(true);
       } else {
         alert("Please enter a valid 10-digit mobile number.");
       }
     }
  };

  const handleModalClose = (result) => {
    setShowModal(false);
    if (result.success) {
      setFormData((prevData) => ({
        ...prevData,
        ['otpVerified']: true,
      }));
      handleSubmit();
      // alert("OTP Verified Successfully!");
    } else {
      console.log("Modal closed without verification.");
    }
  };

  const handleLocationChangeState = (updatedLocation) => {
    console.log('change city state',updatedLocation)
    setFormData((prevData) => ({
      ...prevData,
      state: updatedLocation.state,
      city: updatedLocation.city,
    }));
  };


  return (
    <div className="container mx-auto  p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-semibold mb-6 text-center">Dealer Registration Form</h2>
      <MyGoogleMap onLocationUpdate={handleLocationChange}/>
 
      {/* <CurrentLocationMap onLocationUpdate={handleLocationUpdate} /> */}
      <div className="space-y-6">
        {/* First Name and Last Name */}
        <div className="grid grid-cols-2 gap-4">
 
         
          <div>
            <label className="block text-gray-700">First Name</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              onBlur={handleBlur} 
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onBlur={handleBlur} 
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>
        </div>

        {/* Mobile and Email */}
        <div className="grid grid-cols-2 gap-4">

<div>
  <label className="block text-gray-700">Mobile</label>
  <input
    type="number" // Use text instead of number to handle leading zeros and more flexible validation
    name="mobile"
    value={formData.mobile}
    onChange={(e) =>{ 
      handleChange(e)
      const mobile = e.target.value;
      // Allow only numeric values and limit the length to 10 digits
      if (/^\d{0,10}$/.test(mobile)) {
        setFormData({ ...formData, mobile });
      }
    }} // Handle mobile change with custom validation
    className="w-full p-2 border border-gray-300 rounded-md"
    required
  />
  {formData.mobile && formData.mobile.length !== 10 && (
    <span className="text-red-500 text-sm">Mobile number must be 10 digits long.</span>
  )}
</div>
      <OtpVerificationModal
        showModal={showModal}
        mobile={formData.mobile}
        onClose={handleModalClose}
      />
          <div>
      <label className="block text-gray-700">Password</label>
      <div className="relative">
        <input
          type={showPassword ? 'text' : 'password'}
          name="password"
          value={formData.password}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded-md"
          required
        />
        <span
          onClick={togglePassword}
          className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
        >
          {showPassword ? (
            <AiOutlineEyeInvisible size={20} />
          ) : (
            <AiOutlineEye size={20} />
          )}
        </span>
      </div>
    </div>

          <div>
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              onBlur={handleBlur} 
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">Company/ShopName</label>
            <input
              type="shopName"
              name="shopName"
              value={formData.shopName}
              onChange={handleChange}
              onBlur={handleBlur} 
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          {/* <div>
            <label className="block text-gray-700">Address</label>
            <input
              type="shopAddress"
              name="shopAddress"
              value={formData.shopAddress}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div> */}



<div>
          <label className="block text-gray-700">GST Number</label>
          <input
            type="text"
            name="gst"
            required
            value={formData.gst}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>




          <div>
            <label className="block text-gray-700">Country</label>
            <input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleChange}
              onBlur={handleBlur} 
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div>
          <label className="block text-gray-700">Area/Colony</label>
          <input
            type="text"
            name="area"
            required
            value={formData.area}
            onChange={handleChange}
            onBlur={handleBlur} 
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>

        <div>
          <label className="block text-gray-700">Postal Code</label>
          <input
            type="number"
            name="postalCode"
            required
            value={formData.postalCode}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        </div>

        <div>
      <LocationSelect
        initialState={formData.state}
        initialCity={formData.city}
        onLocationChange={handleLocationChangeState}
      />
       </div>

       <div>
  <label className="block text-gray-700">Address</label>
  <textarea
    type="text"
    name="shopAddress"
    value={formData.shopAddress}
    onChange={handleChange}
    onBlur={handleBlur} 
    className="w-full p-2 border border-gray-300 rounded-md resize-none"
    required
    rows="4" // Set rows to control the height
    placeholder="Enter your address"
  />
</div>

      
        <SelectDealerCategories onSelectionChange={setSelectedCategories} />
        
        <h1 className="text-2xl font-bold mb-4">Upload Your Photos</h1>
      
        {/* GST */}

        {/* Photo and KYC */}
        <div className="grid grid-cols-2 gap-4">
          
          <div>
            <label className="block text-gray-700">KYC Document AadharCard (Upload Image)</label>
           
            {/* <ProductImageUpload onImageChange={handleImageChange} /> */}
            <ProductImageUpload keyName="photo" onImageChange={handleImageChange} />
          </div>
          <div>
            <label className="block text-gray-700">KYC Document PanCard (PDF/Image)</label>
            <ProductImageUpload keyName="kyc" onImageChange={handleImageChange} />
          </div>
        </div>
          {/* Photo 1  and phoot 2 */}
          <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700">SignBoard Image  (Upload Image)</label>
            <ProductImageUpload keyName="photo1" onImageChange={handleImageChange} />
          </div>
        
          <div>
            <label className="block text-gray-700">Inside front side (Upload Image)</label>
            <ProductImageUpload keyName="photo2" onImageChange={handleImageChange} />
          </div>
        </div>
          {/* Photo and KYC */}
          <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700">Inside right side  (Upload Image)</label>
            <ProductImageUpload keyName="photo3" onImageChange={handleImageChange} />
          </div>
        
          <div>
            <label className="block text-gray-700">Inside left side image (Image)</label>
            <ProductImageUpload keyName="photo4" onImageChange={handleImageChange} />
          </div>
        </div>

        {/* GST Certificate */}
        <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-gray-700">GST Certificate (Image)</label>
          <ProductImageUpload keyName="gstCertificate" onImageChange={handleImageChange} />
        </div>

        <div>
            <label className="block text-gray-700">Front Image (Upload Image)</label>
           
            {/* <ProductImageUpload onImageChange={handleImageChange} /> */}
            <ProductImageUpload keyName="frontImage" onImageChange={handleImageChange} />
          </div>
          </div>
          
        <div 
        className="text-center"
        >
                <div className="saveButtonWrapper">
                <button
                  className="saveButton bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-all duration-300"
                  onClick={handleVerifyClick}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="flex items-center space-x-2">
                      <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                      <span>Saving...</span>
                    </div>
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>

        </div>
      </div>
      {isModalOpen && (
        <SuccessModal
          title="Congratulations"
          message={`${message} 'thank you for choosing us`}
          onClose={modalToggle}
        />
      )}

    
    </div>
  );
};
export default RegistrationForm;
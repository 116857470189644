

// import React, { useState } from 'react';

// const DistanceFilter = ({ onFilterChange }) => {
//   const [selectedDistance, setSelectedDistance] = useState(null);
//   const [city, setCity] = useState('');
//   const [state, setState] = useState('');
//   const [country, setCountry] = useState('');

//   const distanceOptions = [
//     { label: '5 km', value: 5 },
//     { label: '10 km', value: 10 },
//     { label: '20 km', value: 20 },
//     { label: 'Within City', value: 'noida' },
//     { label: 'Within State', value: 'Up' },
//     { label: 'Pan India', value: 'india' },
//   ];

//   // Handle button click for distance
//   const handleButtonClick = (value) => {
//     setSelectedDistance(value);
//       onFilterChange(value, city, state, country); // Pass distance, city, state, country to parent   
//   };


//   return (
//     <div className="w-full bg-white p-4 shadow-lg rounded-lg mt-20">
//       <h3 className="text-xl font-semibold text-gray-800 text-center mb-4">Filter by Distance</h3>
//       <div className="flex flex-wrap justify-center gap-3 mb-4">
//         {distanceOptions.map((option) => (
//           <button
//             key={option.value}
//             onClick={() => handleButtonClick(option.value)}
//             className={`px-4 py-2 text-sm font-medium rounded-full transition-all duration-200 ${
//               selectedDistance === option.value
//                 ? 'bg-blue-600 text-white'
//                 : 'bg-gray-200 text-gray-700 hover:bg-blue-500 hover:text-white'
//             }`}
//           >
//             {option.label}
//           </button>
//         ))}
//       </div>

//       {/* Location filter */}
    
//     </div>
//   );
// };

// export default DistanceFilter;

// import React, { useState } from 'react';

// const DistanceFilter = ({ onFilterChange }) => {


//   const [selectedDistance, setSelectedDistance] = useState(null);
//   const [city, setCity] = useState('');
//   const [state, setState] = useState('');
//   const [country, setCountry] = useState('');

//   const distanceOptions = [
//     { label: '2 km', value: 2 },
//     { label: '5 km', value: 5 },
//     { label: '10 km', value: 10 },
//     { label: 'Within City', value: 'noida' },
//     { label: 'Within State', value: 'Up' },
//     { label: 'Pan India', value: 'india' },
//   ];

//   // Handle button click for distance
//   const handleButtonClick = (value) => {
//     setSelectedDistance(value);

//     // Set the corresponding city, state, or country based on the selected value
//     if (value === 'noida') {
//       setCity('Noida');
//       setState('');
//       setCountry('');
//       setSelectedDistance('')
//       // Pass the updated values to the parent component
//     onFilterChange(selectedDistance, city, state, country);
//     } else if (value === 'Up') {
//       setCity('');
//       setState('Uttar Pradesh');
//       setCountry('');
//       setSelectedDistance('')
//       // Pass the updated values to the parent component
//     onFilterChange(selectedDistance, city, state, country);
//     } else if (value === 'india') {
//       setCity('');
//       setState('');
//       setCountry('India');
//       setSelectedDistance('')
//       // Pass the updated values to the parent component
//     onFilterChange(selectedDistance, city, state, country);
//     } else {
//       setCity('');
//       setState('');
//       setCountry('');
//       setSelectedDistance('')
//       // Pass the updated values to the parent component
//     onFilterChange(value, city, state, country);
//     }
//   };

//   return (
//     <div className="w-full bg-[#d6e5fb] p-1 shadow-lg rounded-lg mt-28">
//       <h3 className="text-xl font-semibold text-gray-800 text-center mb-4">Filter by Distance</h3>
//       <div className="flex flex-wrap justify-center gap-3 mb-4">
//         {distanceOptions.map((option) => (
//           <button
//             key={option.value}
//             onClick={() => handleButtonClick(option.value)}
//             className={`px-4 py-2 text-sm font-medium rounded-full transition-all duration-200 ${
//               selectedDistance === option.value
//                 ? 'bg-blue-600 text-white'
//                 : 'bg-gray-200 text-gray-700 hover:bg-blue-500 hover:text-white'
//             }`}
//           >
//             {option.label}
//           </button>
//         ))}
//       </div>

//       {/* Location filter */}
//     </div>
//   );
// };

// export default DistanceFilter;


import React, { useState } from "react";

const DistanceFilter = ({ onFilterChange }) => {
  const [selectedDistance, setSelectedDistance] = useState(null);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");

  const distanceOptions = [
    { label: "2 km", value: 2 },
    { label: "5 km", value: 5 },
    { label: "10 km", value: 10 },
    { label: "Within City", value: "noida" },
    { label: "Within State", value: "Up" },
    { label: "Pan India", value: "india" },
  ];

  const handleButtonClick = (value) => {
    setSelectedDistance(value);

    if (value === "noida") {
      setCity("Noida");
      setState("");
      setCountry("");
      onFilterChange("", "Noida", "", "");
    } else if (value === "Up") {
      setCity("");
      setState("Uttar Pradesh");
      setCountry("");
      onFilterChange("", "", "Uttar Pradesh", "");
    } else if (value === "india") {
      setCity("");
      setState("");
      setCountry("India");
      onFilterChange("", "", "", "India");
    } else {
      setCity("");
      setState("");
      setCountry("");
      onFilterChange(value, "", "", "");
    }
  };

  return (
   
    // <div className="w-full bg-[#d6e5fb] p-4 shadow-lg rounded-lg mt-24 md:mt-16 overflow-x-auto scrollbar-hide">
    //   <h3 className="text-xl font-semibold text-gray-800 text-center mb-4">
    //     Filter by Distance
    //   </h3>
    //   <div className="flex gap-3 overflow-x-auto scrollbar-hide md:justify-center">
    //     {distanceOptions.map((option) => (
    //       <button
    //         key={option.value}
    //         onClick={() => handleButtonClick(option.value)}
    //         className={`whitespace-nowrap px-4 py-2 text-sm font-medium rounded-full transition-all duration-200 ${
    //           selectedDistance === option.value
    //             ? "bg-blue-600 text-white"
    //             : "bg-gray-200 text-gray-700 hover:bg-blue-500 hover:text-white"
    //         }`}
    //       >
    //         {option.label}
    //       </button>
    //     ))}
    //   </div>
    // </div>


    <div className="w-full bg-[#d6e5fb] p-4 shadow-lg rounded-lg mt-24 md:mt-16 overflow-x-auto scrollbar-hide">
  <h3 className="text-xl font-semibold text-gray-800 text-center mb-4">
    Filter by Distance
  </h3>
  <div className="flex gap-3 overflow-x-auto scrollbar-hide md:justify-center">
    {distanceOptions.map((option) => (
      <button
        key={option.value}
        onClick={() => handleButtonClick(option.value)}
        className={`whitespace-nowrap px-4 py-2 text-sm font-medium rounded-full transition-all duration-200 ${
          selectedDistance === option.value
            ? "bg-gray-200 text-gray-700"  // Background is gray and text is dark gray for selected
            : "bg-[#e2f2b2] text-black hover:bg-gray-200 hover:text-white" // Blue background and white text for non-selected
        }`}
      >
        {option.label}
      </button>
    ))}
  </div>
</div>
// this is new changes

  );
};

export default DistanceFilter;

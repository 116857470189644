import React, { useState, useEffect } from "react";
import { Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaMapMarkerAlt, FaPhoneAlt, FaShareAlt, FaStar, FaTag, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useSelector } from "react-redux";
import { generateCoupon } from "../../utils/apiCall";
import "./ProductCard.css";


const ProductCard = ({ title, productItem, onLogin,showCoupon }) => {
  const buyer = useSelector((state) => state.buyer.buyer);
  
  const router = useNavigate();
  const [coupon, setCoupon] = useState(productItem?.Discounts?.Coupons);
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  const [isCouponExpanded, setIsCouponExpanded] = useState(false); // Toggle coupon details
  const [remainingTime, setRemainingTime] = useState("");

  const handleClick = () => {
    router(`/shop/${productItem.id}`, { state: { productItem } });
  };

  const handleShareClick = () => {
    const productLink = `https://www.ratefry.com/shop/${productItem.id}`;
    window.open(`https://wa.me/?text=${encodeURIComponent(`Check out this product: ${productLink}`)}`, "_blank");
  };

  const getGoogleMapsUrl = (latitude, longitude) => {
    const userLatitude = buyer?.latitude;
    const userLongitude = buyer?.longitude;

    if (userLatitude && userLongitude) {
      return `https://www.google.com/maps/dir/?api=1&origin=${userLatitude},${userLongitude}&destination=${latitude},${longitude}`;
    }

    return `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
  };


  const renderStars = (rating) => {
    rating = 5;
    const totalStars = 5;
    return Array.from({ length: totalStars }, (_, i) => (
      <FaStar key={i} color={i < rating ? "#FFA500" : "#d3d3d3"} size={20} />
    ));
  };

  const handleOpenModal = () => {
    onLogin();
  };

  const handleOpenPopup = () =>{
    showCoupon(coupon);
  }

  const handleCoupon = async (discountId) => {
    try {
      setIsCouponLoading(true);
      const response = await generateCoupon(discountId);
      if(response){
         window.location.reload();
      }
      setCoupon(response.data[0]);
      setIsCouponExpanded(true); // Automatically expand details after generation
    } catch (error) {
      console.error("Error generating coupon:", error);
      alert("Failed to generate coupon. Please try again.");
    } finally {
      setIsCouponLoading(false);
    }
  };

  const updateRemainingTime = () => {
    if (coupon?.expiryDate) {
      const now = new Date();
      const expiry = new Date(coupon.expiryDate);
      const remainingTimeMs = expiry - now;
      if (remainingTimeMs > 0) {
        const hours = Math.floor(remainingTimeMs / (1000 * 60 * 60));
        const minutes = Math.floor((remainingTimeMs % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((remainingTimeMs % (1000 * 60)) / 1000);
        setRemainingTime(`${hours}h ${minutes}m ${seconds}s`);
      } else {
        setRemainingTime("Expired");
      }
    }
  };

  useEffect(() => {
    if (coupon) {
      const timer = setInterval(updateRemainingTime, 1000);
      return () => clearInterval(timer); // Cleanup timer
    }
  }, [coupon]);
  

  return (
    <div className="product-card relative">
      <Col style={{ borderColor: "white", borderWidth: 2, margin: 5, padding: 5, borderRadius: 5 }}>
        
      {productItem.Discounts?.discountPercentage && (
    <div className="absolute top-2 right-2 bg-blue-600 text-white px-2 py-1 rounded-lg flex items-center z-50">
      <FaTag className="mr-1" /> 
      <span>{productItem.Discounts.discountPercentage}% Off</span>
    </div>
  )}

        {/* <div className="cursor-pointer flex justify-center items-center" onClick={handleClick}>
          <div 
          // className="h-40w-40"
          style={{ height: 220, width: 220, overflow: "hidden" }}
          >
            <img 
            className="object-cover"
             loading="lazy"
              src={productItem?.imageUri} 
             alt={productItem?.productName}
             style={{ height: 220, width: 220, overflow: "hidden" }}
              />
          </div>
        </div> */}


<div
  className="cursor-pointer flex justify-center items-center"
  onClick={handleClick}
>
  <div
    className="overflow-hidden"
    style={{
      width: "100%", // Full width of the container
      // maxWidth: "250px", 
      height: "auto",
      aspectRatio: "1 / 1", // Ensures square shape
    }}
  >
    <img
      className="object-cover w-full h-full"
      loading="lazy"
      src={productItem?.imageUri}
      alt={productItem?.productName}
    />
  </div>
</div>


        <div className="mt-3">
          <h4 onClick={handleClick} className="truncate cursor-pointer">{productItem?.shopName}</h4>
          <div className="mt-2 mb-2" style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            {renderStars(productItem?.rating || 0)}
          </div>
          <h6 className="truncate cursor-pointer">{productItem?.city}</h6>
          <h6 className="truncate cursor-pointer">{productItem?.area}</h6>
          
        
          <div className="flex items-center justify-center flex-row space-x-4 mt-3">
        
        
          {productItem?.userLocationUrl ? (
        <a
          href={productItem?.userLocationUrl}  // Open the Google Maps URL provided in the userLocationUrl
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center text-blue-500"
        >
          <FaMapMarkerAlt className="mr-2" /> Location
        </a>
      ) : (
          <a
            href={getGoogleMapsUrl(productItem?.latitude, productItem?.longitude)}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center text-blue-500"
          >
            <FaMapMarkerAlt className="mr-2" /> Location
          </a>)}


          {buyer && productItem?.Discounts?.id && !coupon?.id && (

            
              <div className="cursor-pointer flex justify-center items-center" 
              onClick={() => handleCoupon(productItem?.Discounts?.id)}
              >
              <div style={{ height: 50, width: 50, overflow: "hidden" }}>
              <img className="object-cover" loading="lazy" src="coupon.png" alt="sd" />
              </div>
              </div>

              // <button
              //  className=" bg-blue-500 text-white  rounded-lg hover:bg-blue-600 px-2"
              //   onClick={() => handleCoupon(productItem?.Discounts?.id)}
              //   disabled={isCouponLoading || coupon?.id}
              // >
              //   <h className="text-xs"> {isCouponLoading ? "Generating..." : "Coupon"}</h> 
              // </button>
            
            )}

           { !buyer &&  

<div className="cursor-pointer flex justify-center items-center" 
onClick={handleOpenModal}>
<div style={{ height: 50, width: 50, overflow: "hidden" }}>
<img className="object-cover" loading="lazy" src="coupon.png" alt="sd" />
</div>
</div>
           
          //  <button
          //       className=" bg-blue-500 text-white  rounded-lg hover:bg-blue-600 px-2"
          //       onClick={handleOpenModal}
          //       disabled={isCouponLoading || coupon}
          //     >
          //       {/* {isCouponLoading ? "Generating..." : "Login"} */}
          //       <h className="text-xs"> {isCouponLoading ? "Generating..." : "Coupon"}</h> 

          //     </button>
              }

              { coupon?.id &&
              <div className="cursor-pointer flex justify-center items-center" 
               onClick={handleOpenPopup}>
          <div style={{ height: 50, width: 50, overflow: "hidden" }}>
            <img className="object-cover" loading="lazy" src="coupon.png" alt="sd" />
          </div>
        </div>
        }
              
           {/* { coupon?.id && <button
              onClick={handleOpenPopup}
              className=" bg-blue-500 text-white  rounded-lg hover:bg-blue-600 px-1"
            >
             <h className="text-xs">see Coupon</h> 
            </button>} */}

            </div>


          <div className="flex items-center justify-center flex-row space-x-4 mt-3">
            <button
              className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white py-1 px-1 mt-2 rounded-md"
              onClick={handleShareClick}
            >
              <FaShareAlt className="mr-2" /> Share
            </button>
            <a
              href={`tel:${productItem?.mobile}`}
              className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white py-1 px-1 mt-2 rounded-md"
            >
              <FaPhoneAlt className="mr-2" /> Contact
            </a>
          </div>

          <div className="coupon-section mt-2">
          
          </div>
        </div>
      </Col>
    </div>
  );
};

export default ProductCard;


// import React, { useState } from "react";
// import { Col, Button } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { FaMapMarkerAlt, FaPhoneAlt, FaShareAlt, FaStar, FaTag } from "react-icons/fa"; // Added discount icon
// import { useSelector } from "react-redux";
// import axios from 'axios'; // Make sure to import axios for API calls
// import "./ProductCard.css";
// import OTPVerification from "../HomePage/OtpVerification";
// import { generateCoupon } from "../../utils/apiCall";

// // Helper function to handle share
// const shareProduct = (productLink) => {
//   const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(`Check out this product: ${productLink}`)}`;
//   window.open(whatsappShareUrl, "_blank");
// };

// const ProductCard = ({title, productItem,onLogin }) => {
//   const buyer = useSelector((state) => state.buyer.buyer); // Access buyer data from Redux
//   const router = useNavigate();
  
//   const [coupon, setCoupon] = useState(productItem?.Discounts?.Coupons); // State to manage the coupon data
//   const [isCouponLoading, setIsCouponLoading] = useState(false); // State for loading state of coupon generation

//   // Handle product click
//   const handleClick = () => {
//     router(`/shop/${productItem.id}`, { state: { productItem } });
//   };

//   // Handle share button click
//   const handleShareClick = () => {
//     const productLink = `https://www.ratefry.com/shop/${productItem.id}`;
//     shareProduct(productLink);
//   };

//   // Google Maps URL for directions
//   const getGoogleMapsUrl = (latitude, longitude) => {
//     const userLatitude = buyer?.latitude;
//     const userLongitude = buyer?.longitude;
//     return `https://www.google.com/maps/dir/?api=1&origin=${userLatitude || ""},${userLongitude || ""}&destination=${latitude},${longitude}`;
//   };

//   // Generate the star rating
//   const renderStars = (rating) => {
//     rating = 5;
//     const totalStars = 5;
//     let stars = [];
//     for (let i = 0; i < totalStars; i++) {
//       stars.push(
//         <FaStar
//           key={i}
//           color={i < rating ? "#FFA500" : "#d3d3d3"} // Orange for filled stars
//           size={20}
//         />
//       );
//     }
//     return stars;
//   };


//   const handleOpenModal = () => {
//     onLogin();
//   };

//   // Handle coupon generation
//   const handleCoupon = async (discountId) => {
//     try {
//       setIsCouponLoading(true); // Set loading state to true while waiting for the API response
//       const response = await generateCoupon(discountId);
//        console.log('coupon data is here',response);
//       setCoupon(response.data[0]); // Assuming the response returns an array with coupon data
//     } catch (error) {
//       console.error("Error generating coupon:", error);
//       alert("Failed to generate coupon. Please try again.");
//     } finally {
//       setIsCouponLoading(false); // Reset loading state
//     }
//   };

//   // Calculate remaining time for the coupon (valid for 24 hours)
//   const getRemainingTime = (expiryDate) => {
//     const now = new Date();
//     const expiry = new Date(expiryDate);
//     const remainingTime = expiry - now;
//     const hours = Math.floor(remainingTime / (1000 * 60 * 60));
//     const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
//     const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
//     return `${hours}h ${minutes}m ${seconds}s`;
//   };

//   return (
//     <div className="product-card">
//       <Col style={{ borderColor: "white", borderWidth: 2, margin: 5, padding: 5, borderRadius: 5 }}>
//         {productItem.Discounts?.discountPercentage && (
//           <div className="discount-badge">
//             <FaTag className="mr-2" /> {productItem.Discounts.discountPercentage}% Off
//           </div>
//         )}

//         <div className="cursor-pointer flex justify-center items-center" onClick={handleClick}>
//           <div style={{ height: 220, width: 220, overflow: 'hidden' }}>
//             <img className="object-cover" loading="lazy" src={productItem?.imageUri} alt={productItem?.productName} />
//           </div>
//         </div>

//         <div className="mt-3">
//           <h4 onClick={handleClick} className="truncate cursor-pointer">
//             {productItem?.shopName}
//           </h4>

//           {/* Star Rating */}
//           <div className="mt-2" style={{ display: "flex", flexDirection: "row", justifyContent: 'center' }}>
//             {renderStars(productItem?.rating || 0)}
//           </div>

//           <h6 className="truncate cursor-pointer">{productItem?.city}</h6>
//           <h6 className="truncate cursor-pointer">{productItem?.area}</h6>

//           {/* Location */}
//           <a
//             href={getGoogleMapsUrl(productItem?.latitude, productItem?.longitude)}
//             target="_blank"
//             rel="noopener noreferrer"
//             className="flex items-center justify-center text-blue-500"
//           >
//             <FaMapMarkerAlt className="mr-2" /> Location
//           </a>        

//           {/* Share and Contact Buttons */}
//           <div className="flex items-center justify-center flex-row space-x-4 mt-3">
//             <button
//               className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white py-1 px-1 mt-2 rounded-md transition-all duration-200"
//               onClick={handleShareClick}
//             >
//               <FaShareAlt className="mr-2" /> Share
//             </button>

//             <a
//               href={`tel:${productItem?.mobile}`}
//               className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white py-1 px-1 mt-2 rounded-md transition-all duration-200"
//             >
//               <FaPhoneAlt className="mr-2" /> Contact
//             </a>
//           </div>

//           {/* Coupon Generation */}
//        <div className="coupon-section mt-2">
//             { buyer ?
//             <button
//               className="btn btn-success"
//               onClick={() => {handleCoupon(productItem?.Discounts?.id)}}
//               disabled={isCouponLoading || coupon}
//             >
//               {isCouponLoading ? "Generating..." : "Get Coupon"}
//             </button>
//           :   <button
//           className="btn btn-success"
//           onClick={handleOpenModal}
//           disabled={isCouponLoading || coupon}
//         >
//           {isCouponLoading ? "Generating..." : "Get Coupon"}
//         </button>
//           }

//             {coupon && (
//               <div className="coupon-details mt-3">
//                 <h6>Coupon Code: {coupon.couponCode}</h6>
//                 <p>Valid for: {getRemainingTime(coupon.expiryDate)}</p>
//                 <p>Expires at: {new Date(coupon.expiryDate).toLocaleString()}</p>
//               </div>
//             )}

//           </div>
//         </div>
//       </Col>
//     </div>
//   );
// };

// export default ProductCard;



// import React from "react";
// import { Col, Button } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { FaMapMarkerAlt, FaPhoneAlt, FaShareAlt, FaStar } from "react-icons/fa"; // Import the location and star icons
// import { useSelector } from "react-redux";
// import "./ProductCard.css";

// // Helper function to handle share
// const shareProduct = (productLink) => {
//   const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(`Check out this product: ${productLink}`)}`;
//   window.open(whatsappShareUrl, "_blank");
// };

// const ProductCard = ({ productItem, showEditDelete, onEdit, onDelete }) => {
//   const buyer = useSelector((state) => state.buyer.buyer); // Access buyer data from Redux
//   const router = useNavigate();

//   // Handle product click
//   const handleClick = () => {
//     router(`/shop/${productItem.id}`, { state: { productItem } });
//   };

//   // Handle share button click
//   const handleShareClick = () => {
//     const productLink = `https://www.ratefry.com/shop/${productItem.id}`;
//     shareProduct(productLink);
//   };

//   // Google Maps URL for directions
//   const getGoogleMapsUrl = (latitude, longitude) => {
//     const userLatitude = buyer?.latitude;
//     const userLongitude = buyer?.longitude;
//     return `https://www.google.com/maps/dir/?api=1&origin=${userLatitude || ""},${userLongitude || ""}&destination=${latitude},${longitude}`;
//   };

//  // Generate the star rating
//  const renderStars = (rating) => {
//   rating = 5;
//   const totalStars = 5;
//   let stars = [];
//   for (let i = 0; i < totalStars; i++) {
//     stars.push(
//       <FaStar
//         key={i}
//         color={i < rating ? "#FFA500" : "#d3d3d3"} // Orange for filled stars
//         size={20}
//       />
//     );
//   }
//   return stars;
// };

//   return (
//     <div className="product-card">
//       <Col style={{ borderColor: "white", borderWidth: 2, margin: 5, padding: 5, borderRadius: 5 }}>
//         {productItem.Discounts?.discountPercentage && (
//           <span className="discount">{productItem.Discounts.discountPercentage}% Off</span>
//         )}

//         <div className="cursor-pointer flex justify-center items-center" onClick={handleClick}>
//           <div style={{ height: 220, width: 220, overflow: 'hidden' }}>
//             <img className="object-cover" loading="lazy" src={productItem?.imageUri} alt={productItem?.productName} />
//           </div>
//         </div>

//         <div className="mt-3">
//           <h4 onClick={handleClick} className="truncate cursor-pointer">
//             {productItem?.shopName}
//           </h4>
//            {/* Star Rating */}
//             {/* Star Rating (Vertical Layout) */}
//             <div className="mt-2" style={{ display: "flex", flexDirection: "row",justifyContent:'center' }}>
//             {renderStars(productItem?.rating || 0)}
//           </div>
//           {/* <h6 className="truncate cursor-pointer">{productItem?.shopName}</h6> */}
//           <h6 className="truncate cursor-pointer">{productItem?.city}</h6>
//           <h6 className="truncate cursor-pointer">{productItem?.area}</h6>

         

//           {/* Location */}
//           <a
//             href={getGoogleMapsUrl(productItem?.latitude, productItem?.longitude)}
//             target="_blank"
//             rel="noopener noreferrer"
//             className="flex items-center justify-center text-blue-500"
//           >
//             <FaMapMarkerAlt className="mr-2" /> Location
//           </a>

//           {/* Share and Contact Buttons */}
//           <div className="flex items-center justify-center flex-row space-x-4 mt-3">
//             <button
//               className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white py-1 px-1 mt-2 rounded-md transition-all duration-200"
//               onClick={handleShareClick}
//             >
//               <FaShareAlt className="mr-2" /> Share
//             </button>

//             <a
//               href={`tel:${productItem?.mobile}`}
//               className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white py-1 px-1 mt-2 rounded-md transition-all duration-200"
//             >
//               <FaPhoneAlt className="mr-2" /> Contact
//             </a>
//           </div>
//         </div>

//         {showEditDelete && (
//           <div className="edit-delete-actions">
//             <Button variant="warning" onClick={() => onEdit(productItem)}>Edit</Button>
//             <Button variant="danger" onClick={() => onDelete(productItem.id)}>Delete</Button>
//           </div>
//         )}
//       </Col>
//     </div>
//   );
// };

// export default ProductCard;



// import React, { useState } from "react";
// import { Col, Button, Modal } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import { useDispatch, useSelector } from "react-redux";
// import { addToCart } from "../../app/features/cart/cartSlice";
// import { FaMapMarkerAlt, FaPhoneAlt, FaShareAlt } from "react-icons/fa"; // Import the location icon
// import "./ProductCard.css";

// // Helper function to handle share
// const shareProduct = (productLink) => {
//   const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(
//     `Check out this product: ${productLink}`
//   )}`;

//   window.open(whatsappShareUrl, "_blank");
// };

// const ProductCard = ({ title, productItem, showEditDelete, onEdit, onDelete }) => {

//   const buyer = useSelector((state) => state.buyer.buyer); // Access the buyer data from Redux
//   console.log('buyer', buyer);

//   const dispatch = useDispatch();
//   const router = useNavigate();

//   // Handle when product image or name is clicked
//   const handelClick = () => {
//     router(`/shop/${productItem.id}`, { state: { productItem } });  // Pass productItem via state
//   };

//   const handleShareClick = () => {
//     const productLink = `https://www.ratefry.com/shop/${productItem.id}`;
//     shareProduct(productLink);
//   };

//   // Google Maps URL for directions
//   const getGoogleMapsUrl = (latitude, longitude) => {
//     // Optionally, you can pass the user's location as origin if available.
//     const userLatitude = buyer?.latitude;
//     const userLongitude = buyer?.longitude;

//     if (userLatitude && userLongitude) {
//       return `https://www.google.com/maps/dir/?api=1&origin=${userLatitude},${userLongitude}&destination=${latitude},${longitude}`;
//     }

//     return `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
//   };

//   return (
//     <div className="product-card">
//       <Col
//         key={productItem.id}
//         style={{ borderColor: "white", borderWidth: 2, margin: 5, padding: 5, borderRadius: 5 }}
//       >
//         {title === "Big Discount" ? (
//           <span className="discount">{productItem.Discounts.discountPercentage}% Off</span>
//         ) : null}

//         {/* Product Image */}
//         <div
//           className="cursor-pointer flex justify-center items-center"
//           onClick={handelClick}
//         >
//           <div
//             style={{
//               height: 220,
//               width: 220,
//               overflow: 'hidden', // Ensures the image does not overflow beyond the container
//             }}
//           >
//             <img
//               className="object-cover" // Ensures the image covers the fixed dimensions
//               loading="lazy"
//               src={productItem?.imageUri}
//               alt={productItem?.productName}
//             />
//           </div>
//         </div>

//         {/* Product Details */}
//         <div className="mt-3">
//           <h4 onClick={handelClick} className="truncate cursor-pointer">
//             {productItem?.shopName?.charAt(0).toUpperCase() + productItem?.shopName?.slice(1)}
//           </h4>

//           <h6 className="truncate cursor-pointer">{productItem?.shopName}</h6>
//           <h6 className="truncate cursor-pointer">{productItem?.city}</h6>
//           <h6 className="truncate cursor-pointer">{productItem?.area}</h6>

//           {/* Price and Discount */}
//           <div>
//             {/* <h5 className=" truncate">Price: {productItem.price}</h5> */}
//           </div>

//           {/* Owner Details (Optional) */}
//           <div>
//             <a
//               href={getGoogleMapsUrl(productItem?.latitude, productItem?.longitude)}
//               target="_blank"
//               rel="noopener noreferrer"
//               className="flex items-center justify-center text-blue-500 truncate"
//             >
//               <FaMapMarkerAlt className="mr-2" /> Location
//             </a>
//           </div>

//           {/* Share and Contact Buttons */}
//           <div className="flex items-center justify-center flex-row space-x-4 mt-3">
//             {/* Share Button */}
//             <button
//               className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white py-1 px-1 mt-2 rounded-md transition-all duration-200"
//               onClick={handleShareClick}
//             >
//               <FaShareAlt className="mr-2" /> Share
//             </button>

//             {/* Contact Button - Open Dialer */}
//             <a
//               href={`tel:${productItem?.mobile}`}
//               className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white py-1 px-1 mt-2 rounded-md transition-all duration-200"
//             >
//               <FaPhoneAlt className="mr-2" /> Contact
//             </a>
//           </div>
//         </div>

//         {/* Show Edit and Delete options if showEditDelete is true */}
//         {showEditDelete && (
//           <div className="edit-delete-actions">
//             <Button variant="warning" onClick={() => onEdit(productItem)}>Edit</Button>
//             <Button variant="danger" onClick={() => onDelete(productItem.id)}>Delete</Button>
//           </div>
//         )}
//       </Col>
//     </div>
//   );
// };

// export default ProductCard;


// import React, { useState } from "react";
// import { Col, Button, Modal } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import { useDispatch, useSelector } from "react-redux";
// import { addToCart } from "../../app/features/cart/cartSlice";
// import { FaMapMarkerAlt, FaPhoneAlt, FaShareAlt } from "react-icons/fa"; // Import the location icon
// import "./ProductCard.css";

// // import RegisterModal from "./RegisterModal";  // Import the RegisterModal
// import UserRegisterModal from '../../components/user/userRegister';
// // Helper function to handle share
// const shareProduct = (productLink) => {
//   const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(
//     `Check out this product: ${productLink}`
//   )}`;

//   window.open(whatsappShareUrl, "_blank");
// };


// const ProductCard = ({ title, productItem, showEditDelete, onEdit, onDelete }) => {

//   const buyer = useSelector((state) => state.buyer.buyer); // Access the buyer data from Redux
//   console.log('buyer',buyer);

//   const dispatch = useDispatch();
//   const router = useNavigate();

//   const [isModalVisible, setModalVisible] = useState(false);  // To control modal visibility
//   const [isProductAdded, setProductAdded] = useState(false);  // To track if product is added to cart
//   const [isMobileModalVisible, setMobileModalVisible] = useState(false);

//   // Handle when product image or name is clicked
//   const handelClick = () => {
//     router(`/shop/${productItem.id}`, { state: { productItem } });  // Pass productItem via state
//   };

//   // Handle adding the product to the cart
//   // Handle the user registration after clicking the "View Seller" button
//   const handleViewSellerClick = () => {
//     setModalVisible(true);  // Show the modal when the user clicks "View Seller"
//   };

//   // Close the modal
//   const handleCloseModal = () => {
//     setModalVisible(false);
//   };

//   // Register the user (you can save the data to your backend here)
//   const handleRegister = (name, mobile, email) => {
//     // Here you can send the registration details to your backend
//     console.log("User Registered", { name, mobile, email });
//     toast.success("Successfully registered!");
//   };

//   const handleViewMobile = () => {
//     setMobileModalVisible(true);
//   };

//   const handleCloseMobileModal = () => {
//     setMobileModalVisible(false);
//   };

//   const handleShareClick = () => {
//     const productLink = `https://www.ratefry.com/shop/${productItem.id}`;
//     shareProduct(productLink);
//   };


//    // Google Maps URL for directions
//    const getGoogleMapsUrl = (latitude, longitude) => {
//     // Optionally, you can pass the user's location as origin if available.
//     const userLatitude = buyer?.latitude;
//     const userLongitude = buyer?.longitude;

//     if (userLatitude && userLongitude) {
//       return `https://www.google.com/maps/dir/?api=1&origin=${userLatitude},${userLongitude}&destination=${latitude},${longitude}`;
//     }

//     return `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
//   };

//   return (
//     <div className="product-card">
//     <Col 
//     key={productItem.id} 
//     style={{borderColor:"white", borderWidth:2, margin:5,padding:5,borderRadius:5}}
//     >
//       {title === "Big Discount" ? (
//         <span className="discount">{productItem.discount}% Off</span>
//       ) : null}
      
//       {/* Product Image */}
//       <div
//         className="cursor-pointer flex justify-center items-center"
//         onClick={handelClick}
        
//       >

// <div
//   style={{
//     height: 220,
//     width: 220,
//     overflow: 'hidden', // Ensures the image does not overflow beyond the container
//   }}
// >
//   <img
//     className="object-cover" // Ensures the image covers the fixed dimensions
//     loading="lazy"
//     src={productItem.imageUri}
//     alt={productItem.productName}
//     // style={{
//     //   height: '100%',   // Ensures the image height adjusts to the container's height
//     //   width: '100%',    // Ensures the image width adjusts to the container's width
//     //   objectFit: 'cover', // Ensures the image covers the container without distortion
//     // }}
//   />
// </div>

// </div>

      
//       {/* Heart Icon (Wishlist) */}

//       {/* Product Details */}
//       <div className="mt-3">
//         {/* <h4 onClick={handelClick} className="truncate cursor-pointer"> {productItem.productName}</h4> */}
//         <h4 onClick={handelClick} className="truncate cursor-pointer">
//   {productItem.productName.charAt(0).toUpperCase() + productItem.productName.slice(1)} </h4>


//         {/* <h5 className="truncate cursor-pointer">{productItem?.electronicsUser?.firstName }</h5> */}
//         <h6 className="truncate cursor-pointer">{productItem?.electronicsUser?.shopName }</h6>
//         <h6 className="truncate cursor-pointer">{productItem?.electronicsUser?.city}</h6>
//         <h6 className="truncate cursor-pointer">{productItem?.electronicsUser?.area}</h6>
//         {/* { productItem?.distance && <h6 className="truncate cursor-pointer">{productItem?.distance}:km</h6>} */}
      
//         {/* Price and Discount */}
//         <div 
//         // className="price"
//         >
//           <h5 className=" truncate">Price:{productItem.price}</h5>          
//         </div>

//         {/* Owner Details (Optional) */}
//               <div>
//             <a
//               href={getGoogleMapsUrl(productItem?.electronicsUser?.latitude , productItem?.electronicsUser?.longitude)}
//               target="_blank"
//               rel="noopener noreferrer"
//               className="flex items-center  justify-center  text-blue-500  truncate"
//             >
//               <FaMapMarkerAlt className="mr-2 " /> Location
//             </a>
//           </div>
//         {/* // )} */}

//  {/* Share Button */}
//  <div className="flex  items-center justify-center flex-row  space-x-4 mt-3">
//   {/* Share Button */}
//   <button
//     className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white py-1 px-1 mt-2 rounded-md transition-all duration-200"
//     onClick={handleShareClick}
//   >
//     <FaShareAlt className="mr-2" /> Share
//   </button>

//   {/* View Mobile Number Button */}
//   <button
//     className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white py-1 px-1 mt-2 rounded-md transition-all duration-200"
//     onClick={handleViewMobile}
//   >
//     <FaPhoneAlt className="mr-2" /> Contact
//   </button>
// </div>

      

//         {/* Modal for viewing mobile number */}
//         <Modal
//           show={isMobileModalVisible}
//           onHide={handleCloseMobileModal}
//           centered
//         >
//           <Modal.Header closeButton>
//             <Modal.Title>Dealer Contact</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <p className="text-lg">Mobile Number: {productItem?.electronicsUser?.mobile || "Not available"}</p>
//           </Modal.Body>
//           <Modal.Footer>
//             <button
//               className="bg-gray-500 hover:bg-gray-600 text-white py-1 px-3 rounded-md transition duration-200"
//               onClick={handleCloseMobileModal}
//             >
//               Close
//             </button>
//           </Modal.Footer>
//         </Modal>
    


//         {/* Show Edit and Delete options if showEditDelete is true */}
//         {showEditDelete && (
//           <div className="edit-delete-actions">
//             <Button variant="warning" onClick={() => onEdit(productItem)}>Edit</Button>
//             <Button variant="danger" onClick={() => onDelete(productItem.id)}>Delete</Button>
//           </div>
//         )}
//       </div>

//       {/* Show modal if visible */}
//       <UserRegisterModal
//         showModal={isModalVisible} 
//         onClose={handleCloseModal} 
//         onRegister={handleRegister}
//       />
//     </Col>
//     </div>
//   );
// };

// export default ProductCard;







// import React, { useState } from "react";
// import { Col, Modal } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { FaMapMarkerAlt, FaShareAlt, FaPhoneAlt } from "react-icons/fa";
// import "./ProductCard.css";

// // Helper function to handle share
// const shareProduct = (productLink) => {
//   const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(
//     `Check out this product: ${productLink}`
//   )}`;

//   window.open(whatsappShareUrl, "_blank");
// };

// const ProductCard = ({ title, productItem, showEditDelete, onEdit, onDelete }) => {
//   const [isMobileModalVisible, setMobileModalVisible] = useState(false);

//   const handleViewMobile = () => {
//     setMobileModalVisible(true);
//   };

//   const handleCloseMobileModal = () => {
//     setMobileModalVisible(false);
//   };

//   const handleShareClick = () => {
//     const productLink = `https://yourwebsite.com/product/${productItem.id}`;
//     shareProduct(productLink);
//   };

//   return (
//     <div className="product-card">
//       <Col
//         key={productItem.id}
//         style={{ borderColor: "white", borderWidth: 2, margin: 5, padding: 5, borderRadius: 5 }}
//       >
//         {/* Product Image */}
//         <div
//           className="cursor-pointer flex justify-center items-center"
//           onClick={() => window.location.href = `/shop/${productItem.id}`}
//         >
//           <img
//             className="object-contain"
//             src={productItem.imageUri}
//             alt={productItem.productName}
//             style={{ height: 200, width: 300 }}
//           />
//         </div>

//         <div className="mt-2">
//           {/* Product Name */}
//           <h4 className="truncate cursor-pointer text-lg font-semibold">{productItem.productName}</h4>
//           <h5 className="truncate cursor-pointer text-sm text-gray-500">{productItem?.ProductCategory?.categoryName}</h5>

//           {/* Share Button */}
//           <button
//             className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white py-2 px-3 mt-2 rounded-md transition-all duration-200"
//             onClick={handleShareClick}
//           >
//             <FaShareAlt className="mr-2" /> Share
//           </button>

//           {/* View Mobile Number Button */}
//           <button
//             className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white py-2 px-3 mt-2 rounded-md transition-all duration-200"
//             onClick={handleViewMobile}
//           >
//             <FaPhoneAlt className="mr-2" /> View Mobile
//           </button>
//         </div>

//         {/* Modal for viewing mobile number */}
//         <Modal
//           show={isMobileModalVisible}
//           onHide={handleCloseMobileModal}
//           centered
//         >
//           <Modal.Header closeButton>
//             <Modal.Title>Seller Contact</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <p className="text-lg">Mobile Number: {productItem?.owner?.mobile || "Not available"}</p>
//           </Modal.Body>
//           <Modal.Footer>
//             <button
//               className="bg-gray-500 hover:bg-gray-600 text-white py-1 px-3 rounded-md transition duration-200"
//               onClick={handleCloseMobileModal}
//             >
//               Close
//             </button>
//           </Modal.Footer>
//         </Modal>
//       </Col>
//     </div>
//   );
// };

// export default ProductCard;

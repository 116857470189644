




// import React, { useState } from "react";
// import { toast } from "react-toastify";
// import { axiosInstance } from "../../utils/axios";
// import { useNavigate } from "react-router-dom";
// import { sendOtp, verifyOtp } from "../../utils/apiCall";
// import { FaEye, FaEyeSlash } from "react-icons/fa"; // Importing eye icons

// const UserRegisterModal = ({ showModal, onClose, onRegister }) => {
//   const [formData, setFormData] = useState({
//     name: "",
//     mobile: "",
//     email: "",
//     password: "",
//     otp: "",
//   });
//   const [otpSent, setOtpSent] = useState(false); // Track OTP status
//   const [otpVerified, setOtpVerified] = useState(false); // Track OTP verification status
//   const [loading, setLoading] = useState(false); // Track loading state
//   const [showPassword, setShowPassword] = useState(false); // Track password visibility
//   const navigate = useNavigate();

//   const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

//   const handleOtpAction = async (action) => {
//     if (action === "send") {
//       if (!formData.name || !formData.mobile || !formData.password) {
//         toast.error("Please fill all required fields (Name, Mobile, Password)");
//         return;
//       }
//       setLoading(true); // Show loader while sending OTP
//       try {
//         const response = await sendOtp(formData.mobile);
//         if (response.status === 200) {
//           setOtpSent(true);
//           toast.success("OTP sent successfully");
//         } else {
//           toast.error("Failed to send OTP");
//         }
//       } catch (error) {
//         toast.error("Error sending OTP");
//       } finally {
//         setLoading(false); // Hide loader after OTP is sent
//       }
//   }}


//   const handleVerifyOtp = async()=>{
//     if (!formData.otp) {
//       toast.error("Please enter an OTP");
//       return;
//     }
//     setLoading(true); // Show loader while verifying OTP
//     try {
//       const response1 = await verifyOtp(formData.mobile, formData.otp);
//       console.log('response',response1)
//         setOtpVerified(true);
//         const response = await axiosInstance.post('/buyers', formData);
//         console.log('add response',response)
//         setOtpSent(false)
//         toast.success(response?.data?.message);
//         onRegister(formData.name, formData.mobile);
//         setFormData({ name: "", mobile: "", email: "", password: "", otp: "" });
//         onClose();
//     } catch (error) {
//       console.log('error in registering user',error)
//       toast.error("Error verifying OTP");
//     } finally {
//       setLoading(false); // Hide loader after OTP is verified
//     }
//   }


//   const handleRedirectToLogin = () => {
//     navigate("/user/login");
//     onClose();
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const closeModal = ()=>{
//     // setOtpSent(false)
//     onClose()
//   }


//   return (
//     <div className={`fixed inset-0 flex items-center justify-center z-50 ${showModal ? 'block' : 'hidden'}`}>
//       <div className="absolute inset-0 bg-black opacity-50" onClick={closeModal}></div>
//       <div className="bg-white rounded-lg max-w-md mx-auto p-6 z-10 relative">
//         <div className="flex justify-between items-center">
//           <h2 className="text-xl font-semibold">User Registration</h2>
//           <button className="text-gray-600" onClick={closeModal}>&#10005;</button>
//         </div>
//         <form>
//           {!otpSent ? (
//             <>
//               <div className="mb-4">
//                 <label className="block text-sm font-medium mb-2" htmlFor="name">Name</label>
//                 <input
//                   type="text"
//                   name="name"
//                   id="name"
//                   value={formData.name}
//                   onChange={handleChange}
//                   className="w-full p-2 border rounded"
//                   placeholder="Enter your name"
//                 />
//               </div>

//               <div className="mb-4">
//                 <label className="block text-sm font-medium mb-2" htmlFor="mobile">Mobile</label>
//                 <input
//                   type="text"
//                   name="mobile"
//                   id="mobile"
//                   value={formData.mobile}
//                   onChange={handleChange}
//                   className="w-full p-2 border rounded"
//                   placeholder="Enter your mobile"
//                 />
//               </div>

//               <div className="mb-4">
//                 <label className="block text-sm font-medium mb-2" htmlFor="email">Email (Optional)</label>
//                 <input
//                   type="email"
//                   name="email"
//                   id="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                   className="w-full p-2 border rounded"
//                   placeholder="Enter your email (optional)"
//                 />
//               </div>

//               <div className="mb-4">
//                 <label className="block text-sm font-medium mb-2" htmlFor="password">Password</label>
//                 <div className="relative">
//                   <input
//                     type={showPassword ? "text" : "password"}
//                     name="password"
//                     id="password"
//                     value={formData.password}
//                     onChange={handleChange}
//                     className="w-full p-2 border rounded"
//                     placeholder="Enter your password"
//                   />
//                   <span
//                     className="absolute top-3 right-2 cursor-pointer"
//                     onClick={togglePasswordVisibility}
//                   >
//                     {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Using react-icons for password visibility */}
//                   </span>
//                 </div>
//               </div>

//               <button
//                 type="button"
//                 className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
//                 onClick={() => handleOtpAction("send")}
//                 disabled={loading}
//               >
//                 {loading ? "Sending OTP..." : "Send OTP"}
//               </button>
//             </>
//           ) : (
//             <>
//               <div className="mb-4">
//                 <label className="block text-sm font-medium mb-2" htmlFor="otp">Enter OTP</label>
//                 <input
//                   type="text"
//                   name="otp"
//                   id="otp"
//                   value={formData.otp}
//                   onChange={handleChange}
//                   className="w-full p-2 border rounded"
//                   placeholder="Enter OTP"
//                 />
//               </div>

//               <button
//                 type="button"
//                 className="w-full py-2 bg-green-500 text-white rounded hover:bg-green-600"
//                 onClick={() => handleVerifyOtp()}
//                 disabled={loading}
//               >
//                 {loading ? "Verifying OTP..." : "Verify And Register"}
//               </button>
//             </>
//           )}
//         </form>
//       </div>
//     </div>
//   );
// };

// export default UserRegisterModal;


// import React, { useState } from "react";
// import { toast } from "react-toastify";
// import { axiosInstance } from "../../utils/axios";
// import { useNavigate } from "react-router-dom";
// import { sendOtp, verifyOtp } from "../../utils/apiCall";
// import { FaEye, FaEyeSlash } from "react-icons/fa"; // Importing eye icons

// const UserRegisterModal = ({ showModal, onClose, onRegister }) => {
//   const [formData, setFormData] = useState({
//     name: "",
//     mobile: "",
//     email: "",
//     password: "",
//     otp: "",
//   });
//   const [otpSent, setOtpSent] = useState(false); // Track OTP status
//   const [otpVerified, setOtpVerified] = useState(false); // Track OTP verification status
//   const [loading, setLoading] = useState(false); // Track loading state
//   const [showPassword, setShowPassword] = useState(false); // Track password visibility
//   const [mobileError, setMobileError] = useState(""); // Track mobile number error
//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     if (name === "mobile") {
//       let sanitizedValue = value.replace(/[^\d]/g, ""); // Allow only digits
//       sanitizedValue = sanitizedValue.replace(/^\+91|^0/, ""); // Remove +91 or leading 0
//       if (sanitizedValue.length > 10) {
//         sanitizedValue = sanitizedValue.slice(0, 10); // Restrict to 10 digits
//       }
//       setFormData({ ...formData, [name]: sanitizedValue });
//       if (sanitizedValue.length !== 10) {
//         setMobileError("Mobile number must be 10 digits.");
//       } else {
//         setMobileError("");
//       }
//     } else {
//       setFormData({ ...formData, [name]: value });
//     }
//   };

//   const handleOtpAction = async (action) => {
//     if (action === "send") {
//       if (!formData.name || !formData.mobile || !formData.password) {
//         toast.error("Please fill all required fields (Name, Mobile, Password)");
//         return;
//       }
//       if (mobileError || formData.mobile.length !== 10) {
//         toast.error("Please correct the mobile number.");
//         return;
//       }
//       setLoading(true); // Show loader while sending OTP
//       try {
//         const response = await sendOtp(formData.mobile);
//         if (response.status === 200) {
//           setOtpSent(true);
//           toast.success("OTP sent successfully");
//         } else {
//           toast.error("Failed to send OTP");
//         }
//       } catch (error) {
//         toast.error("Error sending OTP");
//       } finally {
//         setLoading(false); // Hide loader after OTP is sent
//       }
//     }
//   };

//   const handleVerifyOtp = async () => {
//     if (!formData.otp) {
//       toast.error("Please enter an OTP");
//       return;
//     }
//     setLoading(true); // Show loader while verifying OTP
//     try {
//       const response1 = await verifyOtp(formData.mobile, formData.otp);
//       console.log("response", response1);
//       setOtpVerified(true);
//       const response = await axiosInstance.post("/buyers", formData);
//       console.log("add response", response);
//       setOtpSent(false);
//       toast.success(response?.data?.message);
//       onRegister(formData.name, formData.mobile);
//       setFormData({ name: "", mobile: "", email: "", password: "", otp: "" });
//       onClose();
//     } catch (error) {
//       console.log("error in registering user", error);
//       toast.error("Error verifying OTP");
//     } finally {
//       setLoading(false); // Hide loader after OTP is verified
//     }
//   };

//   const handleRedirectToLogin = () => {
//     navigate("/user/login");
//     onClose();
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const closeModal = () => {
//     onClose();
//   };

//   return (
//     <div className={`fixed inset-0 flex items-center justify-center z-50 ${showModal ? "block" : "hidden"}`}>
//       <div className="absolute inset-0 bg-black opacity-50" onClick={closeModal}></div>
//       <div className="bg-white rounded-lg max-w-md mx-auto p-6 z-10 relative">
//         <div className="flex justify-between items-center">
//           <h2 className="text-xl font-semibold">User Registration</h2>
//           <button className="text-gray-600" onClick={closeModal}>&#10005;</button>
//         </div>
//         <form>
//           {!otpSent ? (
//             <>
//               <div className="mb-4">
//                 <label className="block text-sm font-medium mb-2" htmlFor="name">
//                   Name
//                 </label>
//                 <input
//                   type="text"
//                   name="name"
//                   id="name"
//                   value={formData.name}
//                   onChange={handleChange}
//                   className="w-full p-2 border rounded"
//                   placeholder="Enter your name"
//                 />
//               </div>

//               <div className="mb-4">
//                 <label className="block text-sm font-medium mb-2" htmlFor="mobile">
//                   Mobile
//                 </label>
//                 <input
//                   type="text"
//                   name="mobile"
//                   id="mobile"
//                   value={formData.mobile}
//                   onChange={handleChange}
//                   className="w-full p-2 border rounded"
//                   placeholder="Enter your mobile"
//                 />
//                 {mobileError && <p className="text-red-500 text-sm mt-1">{mobileError}</p>}
//               </div>

//               <div className="mb-4">
//                 <label className="block text-sm font-medium mb-2" htmlFor="email">
//                   Email (Optional)
//                 </label>
//                 <input
//                   type="email"
//                   name="email"
//                   id="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                   className="w-full p-2 border rounded"
//                   placeholder="Enter your email (optional)"
//                 />
//               </div>

//               <div className="mb-4">
//                 <label className="block text-sm font-medium mb-2" htmlFor="password">
//                   Password
//                 </label>
//                 <div className="relative">
//                   <input
//                     type={showPassword ? "text" : "password"}
//                     name="password"
//                     id="password"
//                     value={formData.password}
//                     onChange={handleChange}
//                     className="w-full p-2 border rounded"
//                     placeholder="Enter your password"
//                   />
//                   <span
//                     className="absolute top-3 right-2 cursor-pointer"
//                     onClick={togglePasswordVisibility}
//                   >
//                     {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Using react-icons for password visibility */}
//                   </span>
//                 </div>
//               </div>

//               <button
//                 type="button"
//                 className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
//                 onClick={() => handleOtpAction("send")}
//                 disabled={loading || mobileError || formData.mobile.length !== 10}
//               >
//                 {loading ? "Sending OTP..." : "Send OTP"}
//               </button>
//             </>
//           ) : (
//             <>
//               <div className="mb-4">
//                 <label className="block text-sm font-medium mb-2" htmlFor="otp">
//                   Enter OTP
//                 </label>
//                 <input
//                   type="text"
//                   name="otp"
//                   id="otp"
//                   value={formData.otp}
//                   onChange={handleChange}
//                   className="w-full p-2 border rounded"
//                   placeholder="Enter OTP"
//                 />
//               </div>

//               <button
//                 type="button"
//                 className="w-full py-2 bg-green-500 text-white rounded hover:bg-green-600"
//                 onClick={() => handleVerifyOtp()}
//                 disabled={loading}
//               >
//                 {loading ? "Verifying OTP..." : "Verify And Register"}
//               </button>
//             </>
//           )}
//         </form>
//       </div>
//     </div>
//   );
// };

// export default UserRegisterModal;







// import React, { useState } from "react";
// import { toast } from "react-toastify";
// import { axiosInstance } from "../../utils/axios";
// import { useNavigate } from "react-router-dom";
// import { sendOtp, verifyOtp } from "../../utils/apiCall";
// import { FaEye, FaEyeSlash } from "react-icons/fa"; // Importing eye icons

// const UserRegisterModal = ({ showModal, onClose, onRegister }) => {
//   const [formData, setFormData] = useState({
//     name: "",
//     mobile: "",
//     email: "",
//     password: "",
//     otp: "",
//   });
//   const [otpSent, setOtpSent] = useState(false); // Track OTP status
//   const [otpVerified, setOtpVerified] = useState(false); // Track OTP verification status
//   const [loading, setLoading] = useState(false); // Track loading state
//   const [showPassword, setShowPassword] = useState(false); // Track password visibility
//   const [mobileError, setMobileError] = useState(""); // Track mobile number error
//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     if (name === "mobile") {
//       let sanitizedValue = value.replace(/[^\d]/g, ""); // Allow only digits
//       sanitizedValue = sanitizedValue.replace(/^\+91|^0/, ""); // Remove +91 or leading 0
//       if (sanitizedValue.length > 10) {
//         sanitizedValue = sanitizedValue.slice(0, 10); // Restrict to 10 digits
//       }
//       setFormData({ ...formData, [name]: sanitizedValue });
//       if (sanitizedValue.length !== 10) {
//         setMobileError("Mobile number must be 10 digits.");
//       } else {
//         setMobileError("");
//       }
//     } else {
//       setFormData({ ...formData, [name]: value });
//     }
//   };

//   const handleOtpAction = async (action) => {
//     if (action === "send") {
//       if (!formData.name || !formData.mobile || !formData.password) {
//         toast.error("Please fill all required fields (Name, Mobile, Password)");
//         return;
//       }
//       if (mobileError || formData.mobile.length !== 10) {
//         toast.error("Please correct the mobile number.");
//         return;
//       }

//       setLoading(true); // Show loader while sending OTP
//       try {
//         // Check if user already exists
//         const isExistResponse = await axiosInstance.post("/isexist/buyer/", { mobile: formData.mobile });
//         if (isExistResponse.data.status) {
//           setLoading(false);
//           toast.error(isExistResponse.data.message);
//           return;
//         }

//         // If not exists, send OTP
//         const response = await sendOtp(formData.mobile);
//         if (response.status === 200) {
//           setOtpSent(true);
//           toast.success("OTP sent successfully");
//         } else {
//           toast.error("Failed to send OTP");
//         }
//       } catch (error) {
//         toast.error("Error checking user existence or sending OTP");
//       } finally {
//         setLoading(false); // Hide loader after OTP is sent
//       }
//     }
//   };

//   const handleVerifyOtp = async () => {
//     if (!formData.otp) {
//       toast.error("Please enter an OTP");
//       return;
//     }
//     setLoading(true); // Show loader while verifying OTP
//     try {
//       const response1 = await verifyOtp(formData.mobile, formData.otp);
//       console.log("response", response1);
//       setOtpVerified(true);
//       const response = await axiosInstance.post("/buyers", formData);
//       console.log("add response", response);
//       setOtpSent(false);
//       toast.success(response?.data?.message);
//       onRegister(formData.name, formData.mobile);
//       setFormData({ name: "", mobile: "", email: "", password: "", otp: "" });
//       onClose();
//     } catch (error) {
//       console.log("error in registering user", error);
//       toast.error("Error verifying OTP");
//     } finally {
//       setLoading(false); // Hide loader after OTP is verified
//     }
//   };

//   const handleRedirectToLogin = () => {
//     navigate("/user/login");
//     onClose();
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const closeModal = () => {
//     onClose();
//   };

//   return (
//     <div className={`fixed inset-0 flex items-center justify-center z-50 ${showModal ? "block" : "hidden"}`}>
//       <div className="absolute inset-0 bg-black opacity-50" onClick={closeModal}></div>
//       <div className="bg-white rounded-lg max-w-md mx-auto p-6 z-10 relative">
//         <div className="flex justify-between items-center">
//           <h2 className="text-xl font-semibold">User Registration</h2>
//           <button className="text-gray-600" onClick={closeModal}>&#10005;</button>
//         </div>
//         <form>
//           {!otpSent ? (
//             <>
//               <div className="mb-4">
//                 <label className="block text-sm font-medium mb-2" htmlFor="name">
//                   Name
//                 </label>
//                 <input
//                   type="text"
//                   name="name"
//                   id="name"
//                   value={formData.name}
//                   onChange={handleChange}
//                   className="w-full p-2 border rounded"
//                   placeholder="Enter your name"
//                 />
//               </div>

//               <div className="mb-4">
//                 <label className="block text-sm font-medium mb-2" htmlFor="mobile">
//                   Mobile
//                 </label>
//                 <input
//                   type="text"
//                   name="mobile"
//                   id="mobile"
//                   value={formData.mobile}
//                   onChange={handleChange}
//                   className="w-full p-2 border rounded"
//                   placeholder="Enter your mobile"
//                 />
//                 {mobileError && <p className="text-red-500 text-sm mt-1">{mobileError}</p>}
//               </div>

//               <div className="mb-4">
//                 <label className="block text-sm font-medium mb-2" htmlFor="email">
//                   Email (Optional)
//                 </label>
//                 <input
//                   type="email"
//                   name="email"
//                   id="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                   className="w-full p-2 border rounded"
//                   placeholder="Enter your email (optional)"
//                 />
//               </div>

//               <div className="mb-4">
//                 <label className="block text-sm font-medium mb-2" htmlFor="password">
//                   Password
//                 </label>
//                 <div className="relative">
//                   <input
//                     type={showPassword ? "text" : "password"}
//                     name="password"
//                     id="password"
//                     value={formData.password}
//                     onChange={handleChange}
//                     className="w-full p-2 border rounded"
//                     placeholder="Enter your password"
//                   />
//                   <span
//                     className="absolute top-3 right-2 cursor-pointer"
//                     onClick={togglePasswordVisibility}
//                   >
//                     {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Using react-icons for password visibility */}
//                   </span>
//                 </div>
//               </div>

//               <button
//                 type="button"
//                 className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
//                 onClick={() => handleOtpAction("send")}
//                 disabled={loading || mobileError || formData.mobile.length !== 10}
//               >
//                 {loading ? "Sending OTP..." : "Send OTP"}
//               </button>
//             </>
//           ) : (
//             <>
//               <div className="mb-4">
//                 <label className="block text-sm font-medium mb-2" htmlFor="otp">
//                   Enter OTP
//                 </label>
//                 <input
//                   type="text"
//                   name="otp"
//                   id="otp"
//                   value={formData.otp}
//                   onChange={handleChange}
//                   className="w-full p-2 border rounded"
//                   placeholder="Enter OTP"
//                 />
//               </div>

//               <button
//                 type="button"
//                 className="w-full py-2 bg-green-500 text-white rounded hover:bg-green-600"
//                 onClick={() => handleVerifyOtp()}
//                 disabled={loading}
//               >
//                 {loading ? "Verifying OTP..." : "Verify And Register"}
//               </button>
//             </>
//           )}
//         </form>
//       </div>
//     </div>
//   );
// };

// export default UserRegisterModal;





import './userregister.css'
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { axiosInstance } from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import { sendOtp, verifyOtp } from "../../utils/apiCall";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Importing eye icons

const UserRegisterModal = ({ showModal, onClose, onRegister }) => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    otp: "",
  });
  const [otpSent, setOtpSent] = useState(false); // Track OTP status
  const [otpVerified, setOtpVerified] = useState(false); // Track OTP verification status
  const [loading, setLoading] = useState(false); // Track loading state
  const [showPassword, setShowPassword] = useState(false); // Track password visibility
  const [mobileError, setMobileError] = useState(""); // Track mobile number error
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobile") {
      let sanitizedValue = value.replace(/[^\d]/g, ""); // Allow only digits
      sanitizedValue = sanitizedValue.replace(/^\+91|^0/, ""); // Remove +91 or leading 0
      if (sanitizedValue.length > 10) {
        sanitizedValue = sanitizedValue.slice(0, 10); // Restrict to 10 digits
      }
      setFormData({ ...formData, [name]: sanitizedValue });
      if (sanitizedValue.length !== 10) {
        setMobileError("Mobile number must be 10 digits.");
      } else {
        setMobileError("");
      }
    } else {

      // setFormData({ ...formData, [name]: value });
 
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
      
    }
  };

  const handleBlur = (e) => {
    // When input loses focus, convert the name to uppercase
    // const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      name: prevData.name.toUpperCase()
    }));
  };


  const handleOtpAction = async (action) => {
    if (action === "send") {
      if (!formData.name || !formData.mobile || !formData.password) {
        toast.error("Please fill all required fields (Name, Mobile, Password)");
        return;
      }
      if (mobileError || formData.mobile.length !== 10) {
        toast.error("Please correct the mobile number.");
        return;
      }

      setLoading(true); // Show loader while sending OTP
      try {
        // Check if user already exists
        const isExistResponse = await axiosInstance.post("/isexist/buyer/", { mobile: formData.mobile });
        if (isExistResponse.data.status) {
          setLoading(false); // Hide loader after error
          toast.error(isExistResponse.data.message);
          return; // Stop further execution if user already exists
        }

        // If not exists, send OTP
        const response = await sendOtp(formData.mobile);
        if (response.status === 200) {
          setOtpSent(true);
          toast.success("OTP sent successfully");
        } else {
          toast.error("Failed to send OTP");
        }
      } catch (error) {
        toast.error("Error checking user existence or sending OTP");
      } finally {
        setLoading(false); // Hide loader after OTP is sent
      }
    }
  };

  const handleVerifyOtp = async () => {
    if (!formData.otp) {
      toast.error("Please enter an OTP");
      return;
    }
    setLoading(true); // Show loader while verifying OTP
    try {
      const response1 = await verifyOtp(formData.mobile, formData.otp);
      console.log("response", response1);
      if(response1.data.status){
      setOtpVerified(true);
      const response = await axiosInstance.post("/buyers", formData);
      console.log("add response", response);
      setOtpSent(false);
      toast.success(response?.data?.message);
      onRegister(formData.name, formData.mobile);
      setFormData({ name: "", mobile: "", email: "", password: "", otp: "" });
      onClose();
      }else{
       toast.error(response1.data.message)
      }
    } catch (error) {
      console.log("error in registering user", error);
      toast.error("Error verifying OTP");
    } finally {
      setLoading(false); // Hide loader after OTP is verified
    }
  };

  const handleRedirectToLogin = () => {
    navigate("/user/login");
    onClose();
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const closeModal = () => {
    onClose();
  };


  useEffect(() => {
    if (showModal) {
      document.body.classList.add("modal-open"); // Add class to disable scroll
    } else {
      document.body.classList.remove("modal-open"); // Remove class when modal is closed
    }
  }, [showModal]);

  

  return (
    <div className={`fixed inset-0 flex items-center justify-center mt-20 z-50 ${showModal ? "block" : "hidden"}`}>
    <div className="modal-overlay" onClick={closeModal}></div>
      <div className="absolute inset-0 bg-black opacity-50" onClick={closeModal}></div>
      <div className="bg-white rounded-lg max-w-md mx-auto p-6 z-10 relative">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">User Registration</h2>
          <button className="text-gray-600" onClick={closeModal}>&#10005;</button>
        </div>
        <form>
          {!otpSent ? (
            <>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  onBlur={handleBlur} 
                  className="w-full p-2 border rounded"
                  placeholder="Enter your name"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium mb-2" htmlFor="mobile">
                  Mobile
                </label>
                <input
                  type="number"
                  name="mobile"
                  id="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  placeholder="Enter your mobile"
                />
                {mobileError && <p className="text-red-500 text-sm mt-1">{mobileError}</p>}
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium mb-2" htmlFor="email">
                  Email (Optional)
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onBlur={handleBlur} 
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  placeholder="Enter your email (optional)"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium mb-2" htmlFor="password">
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    value={formData.password}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    placeholder="Enter your password"
                  />
                  <span
                    className="absolute top-3 right-2 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Using react-icons for password visibility */}
                  </span>
                </div>
              </div>

              <button
                type="button"
                className={`w-full py-2 rounded ${loading || mobileError || formData.mobile.length !== 10 ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}
                onClick={() => handleOtpAction("send")}
                disabled={loading || mobileError || formData.mobile.length !== 10}
              >
                {"Send OTP"}
              </button>
 
            </>
          ) : (
            <>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2" htmlFor="otp">
                  Enter OTP
                </label>
                <input
                  type="number"
                  name="otp"
                  id="otp"
                  value={formData.otp}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  placeholder="Enter OTP"
                />
              </div>

              <button
                type="button"
                className="w-full py-2 bg-green-500 text-white rounded hover:bg-green-600"
                onClick={() => handleVerifyOtp()}
                disabled={loading}
              >
                {loading ? "Verifying OTP..." : "Verify And Register"}
              </button>
            </>
          )}
        </form>
      </div>
      
    </div>
  );
};

export default UserRegisterModal;

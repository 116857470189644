
// import React from "react"
// import "./style.css"
// import { Col, Container, Row } from "react-bootstrap"
// import { useNavigate } from "react-router-dom"

// const Footer = () => {

//   const navigate = useNavigate();

//   const navigatePage = (page)=>{
//      navigate(page);
//      // Scroll the page to the top
//     window.scrollTo(0, 0);

//   }


//   return (
//     <footer>
//         <Container  className="bg-[#0f3460] py-12">
//           <Row className="footer-row">
//             <Col md={3} sm={5} className='box'>
//               <div className="logo">
//                   <ion-icon name="bag"></ion-icon>
//                   <h1>Ratefry</h1>
//               </div>
//               <p className="text-sm text-white mt-4">
//              At Ratefry, you can easily check the prices of any product you’re interested in. 
//              Our platform allows you to compare rates from various stores, 
//              helping you make informed purchasing decisions.
//            </p>            
//            </Col>
//             <Col md={3} sm={5} className='box'>
//               <h2>About Us</h2>
//               <ul>
//                 <li>Careers</li>
//                 <li>Our Stores</li>
//                 <li>Our Cares</li>
//                 <li onClick={()=>navigatePage('/terms-conditions')}>Terms & Conditions</li>
//                 <li>Privacy Policy</li>
//               </ul>
//             </Col>
//             <Col md={3} sm={5} className='box'>
//               <h2>Customer Care</h2>
//               <ul>
//                 <li>Help Center </li>
//                 <li>How to Buy </li>
//                 <li>Track Your Order </li>
//                 <li>Corporate & Bulk Purchasing </li>
//                 <li>Returns & Refunds </li>
//               </ul>
//             </Col>
//             <Col md={3} sm={5} className='box'>
//               <h2>Contact Us</h2>
//               <ul>
//                 <li>70 Square South, Mumbai, India</li>
//                 <li>Email: support@ratefry.com</li>
//                 <li>Phone: 9289096788</li>
//               </ul>
//             </Col>
//           </Row>
//           <Col>
//           <li>
        
//           </li>
//           <div className="logo justify-center text-center">
//   <h1 className="text-sm">Powered by Dosso21 services pvt ltd</h1>
// </div>

//           </Col>
//         </Container>
//     </footer>
//   )
// }

// export default Footer







import React from "react";
import "./style.css";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaWhatsapp } from 'react-icons/fa'; 


const Footer = () => {
  const navigate = useNavigate();

  const navigatePage = (page) => {
    navigate(page);
    // Scroll the page to the top
    window.scrollTo(0, 0);
  };

  return (
    <footer className="mt-5">
      <Container className="bg-[#0f3460] py-12">
        <Row className="footer-row">
          <Col md={3} sm={5} className="box">
            <div className="logo">
              <ion-icon name="bag"></ion-icon>
              <h1>Ratefry</h1>
            </div>
            <p className="text-sm text-white mt-4">
              At Ratefry, you can easily check the prices of any product you’re
              interested in. Our platform allows you to compare rates from various
              stores, helping you make informed purchasing decisions.
            </p>
          </Col>
          <Col md={3} sm={5} className="box">
            <h2>About Us</h2>
            <ul>
              <li>Careers</li>
              <li>Our Stores</li>
              <li>Our Cares</li>
              <li onClick={() => navigatePage('/terms-conditions')}>Terms & Conditions</li>
              <li>Privacy Policy</li>
            </ul>
          </Col>
          <Col md={3} sm={5} className="box">
            <h2>Customer Care</h2>
            <ul>
              <li>Help Center </li>
              <li>How to Buy </li>
              <li>Track Your Order </li>
              <li>Corporate & Bulk Purchasing </li>
              <li>Returns & Refunds </li>
            </ul>
          </Col>
          <Col md={3} sm={5} className="box">
            <h2>Contact Us</h2>
            <ul>
              <li>70 Square South, Mumbai, India</li>
              <li>Email: support@ratefry.com</li>
              <li style={{ display: 'flex', alignItems: 'center' }}>
                {/* WhatsApp Icon and Text */}
                <FaWhatsapp style={{ marginRight: '8px' }} />
                9289096788 (WhatsApp/Only)
              </li>

            </ul>
          </Col>
        </Row>

        {/* Copyright Section */}
        <Row className="mt-4">
          <Col className="text-center">
            <div className="logo justify-center">
              <h2 className="text-sm text-white">
                Powered by Dosso21 services pvt ltd
              </h2>
            </div>
            <p className="text-xs text-white mt-1">
              &copy; {new Date().getFullYear()} Ratefry. All Rights Reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;





// import React from "react";
// import "./style.css";
// import { Col, Container, Row } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";

// const Footer = () => {
//   const navigate = useNavigate();

//   const navigatePage = (page) => {
//     navigate(page);
//     // Scroll the page to the top
//     window.scrollTo(0, 0);
//   };

//   return (
//     <footer>
//       <Container>
//         <Row className="footer-row">
//           <Col md={3} sm={5} className="box">
//             <div className="logo">
//               <ion-icon name="bag"></ion-icon>
//               <h1>Multimart</h1>
//             </div>
//             <p>
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor
//               libero id et, in gravida. Sit diam duis mauris nulla cursus. Erat
//               et lectus vel ut sollicitudin elit at amet.
//             </p>
//           </Col>

//           <Col md={3} sm={5} className="box">
//             <h2>About Us</h2>
//             <ul>
//               {/* Only keep Terms & Conditions */}
//               <li onClick={() => navigatePage("/terms-conditions")}>
//                 Terms & Conditions
//               </li>
//             </ul>
//           </Col>

//           {/* Commenting out the other sections */}
//           <Col md={3} sm={5} className='box'>
//             <h2>Customer Care</h2>
//             <ul>
//               <li>Help Center</li>
//               <li>How to Buy</li>
//               <li>Track Your Order</li>
//               <li>Corporate & Bulk Purchasing</li>
//               <li>Returns & Refunds</li>
//             </ul>
//           </Col>
//           <Col md={3} sm={5} className='box'>
//             <h2>Contact Us</h2>
//             <ul>
//               <li>70 Washington Square South, New York, NY 10012, United States</li>
//               <li>Email: uilib.help@gmail.com</li>
//               <li>Phone: +1 1123 456 780</li>
//             </ul>
//           </Col>
//         </Row>

//         {/* Moving Powered by text to the center */}
//         <Row className="justify-content-center">
//           <Col className="text-center">
//             <div className="logo">
//               <h1>Powered by Dosso21 Services Pvt Ltd</h1>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     </footer>
//   );
// };

// export default Footer;


// import React from "react";
// import { Col, Container, Row } from "react-bootstrap"; // Bootstrap for layout (optional)
// import { useNavigate } from "react-router-dom";

// const Footer = () => {
//   const navigate = useNavigate();

//   const navigatePage = (page) => {
//     navigate(page);
//     // Scroll the page to the top
//     window.scrollTo(0, 0);
//   };

//   return (
//     <footer className="bg-[#0f3460] py-12">
//       <Container>
//         <Row className="footer-row">
//           <Col md={3} sm={5} className="box">
//             <div className="logo flex items-center">
//               {/* <ion-icon name="bag" className="text-xl"></ion-icon> */}
//               <h1 className="text-2xl font-bold ml-2 text-white">Ratefry</h1>
//             </div>
//             <p className="text-sm text-white mt-4">
//             At Ratefry, you can easily check the prices of any product you’re interested in. 
//             Our platform allows you to compare rates from various stores, 
//             helping you make informed purchasing decisions.
//             </p>
//           </Col>

//           <Col md={3} sm={5} className="box">
//             {/* <h2 className="text-xl font-semibold">About Us</h2> */}
//             <ul className="mt-4 space-y-2">
//               {/* Only keep Terms & Conditions */}
//               <li
//                 onClick={() => navigatePage("/terms-conditions")}
//                 className="text-white cursor-pointer hover:underline "
//               >
//                 Terms & Conditions
//               </li>
//             </ul>
//           </Col>
//         </Row>

//         {/* Moving Powered by text to the center */}
//         <Row className="justify-center mt-8">
//           <Col className="text-center">
//             <div className="logo">
//               <h1 className="text-lg font-semibold text-white">Powered by Dosso21 Services Pvt Ltd</h1>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     </footer>
//   );
// };

// export default Footer;






// import React from "react";

// const Footer = () => {
//   const footerLinks = [
//     {
//       title: "AbeBooks",
//       description: "Books, art & collectibles",
//     },
//     {
//       title: "Amazon Web Services",
//       description: "Scalable Cloud Computing Services",
//     },
//     {
//       title: "Audible",
//       description: "Download Audio Books",
//     },
//     {
//       title: "IMDb",
//       description: "Movies, TV & Celebrities",
//     },
//     {
//       title: "Shopbop",
//       description: "Designer Fashion Brands",
//     },
//     {
//       title: "Amazon Business",
//       description: "Everything For Your Business",
//     },
//     {
//       title: "Prime Now",
//       description: "2-Hour Delivery on Everyday Items",
//     },
//     {
//       title: "Amazon Prime Music",
//       description: "100 million songs, ad-free\nOver 15 million podcast episodes",
//     },
//   ];

//   return (
//     <footer className="bg-gray-900 text-white py-8 px-4">
//       <div className="max-w-7xl mx-auto grid grid-cols-2 md:grid-cols-4 gap-8">
//         {footerLinks.map((link, index) => (
//           <div key={index}>
//             <h3 className="font-semibold">{link.title}</h3>
//             <p className="text-sm text-gray-400">{link.description}</p>
//           </div>
//         ))}
//       </div>
//       <div className="border-t border-gray-700 mt-8 pt-4 text-center text-gray-400 text-sm">
//         <p>
//           Conditions of Use & Sale · Privacy Notice · Interest-Based Ads
//         </p>
//         <p className="mt-2">
//           © 1996-2024, Amazon.com, Inc. or its affiliates
//         </p>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

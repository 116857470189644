


import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const LocationSelect = ({ initialState, initialCity, onLocationChange }) => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  // Fetch states on component mount
  useEffect(() => {
    fetch('https://ratefry.com/api/electronics/states')
      .then((response) => response.json())
      .then((data) => {
        const formattedStates = data.map((state) => ({
          value: state.id,
          label: state.stateName,
        }));
        setStates(formattedStates);

        // If initialState is passed as state name, find the corresponding state ID
        if (initialState) {
          const initialStateData = formattedStates.find(state => state.label === initialState);
          setSelectedState(initialStateData?.value || null);
        }
      });
  }, [initialState]);

  // Fetch cities whenever the selected state changes
  useEffect(() => {
    if (selectedState) {
      fetch(`https://ratefry.com/api/electronics/states/${selectedState}/cities`)
        .then((response) => response.json())
        .then((data) => {
          const formattedCities = data.map((city) => ({
            value: city.id,
            label: city.cityName,
          }));
          setCities(formattedCities);

          // If initialCity is passed, find the corresponding city ID
          if (initialCity) {
            const initialCityData = formattedCities.find(city => city.label === initialCity);
            setSelectedCity(initialCityData?.value || null);
          } else {
            setSelectedCity(null); // Clear city when state changes and no city is selected
          }
        });
    } else {
      setCities([]); // Clear cities if no state is selected
      setSelectedCity(null); // Also clear the selected city when no state is selected
    }
  }, [selectedState, initialCity]);

  // Handle state change
  const handleStateChange = (option) => {
    const newStateId = option?.value || null;
    setSelectedState(newStateId);
    setSelectedCity(null); // Reset city when state changes

    // Pass state and city names (not IDs) to the parent
    onLocationChange({ state: option?.label || null, city: null });
  };

  // Handle city change
  const handleCityChange = (option) => {
    const newCityId = option?.value || null;
    setSelectedCity(newCityId);

    // Pass both state and city names to the parent
    const selectedStateLabel = states.find((state) => state.value === selectedState)?.label || null;
    const selectedCityLabel = option?.label || null;
    onLocationChange({ state: selectedStateLabel, city: selectedCityLabel });
  };

  return (
    <div className="">
      {/* <h2 className="text-xl font-semibold mb-4">Select Your Location</h2> */}
      <div className="flex flex-row space-x-4">
        <div className="mb-4 sm:mb-0 w-full sm:w-1/2">
          <label className="block text-gray-700 mb-2">State</label>
          <Select
            options={states}
            value={states.find((state) => state.value === selectedState) || null}
            onChange={handleStateChange}
            className="w-full"
          />
        </div>
        <div className="mb-4 sm:mb-0 w-full sm:w-1/2">
          <label className="block text-gray-700 mb-2">City</label>
          <Select
            options={cities}
            value={cities.find((city) => city.value === selectedCity) || null}
            onChange={handleCityChange}
            className="w-full"
            isDisabled={!selectedState}
          />
        </div>
      </div>
    </div>
  );
};

export default LocationSelect;

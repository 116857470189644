import React, { useEffect, useState } from "react";
import { FiDollarSign, FiArrowUpRight, FiFileText, FiBriefcase, FiPlus, FiUserPlus } from "react-icons/fi";
import AddSellerModal from "./AddSellerModal";
import { getSingleAdminDetails } from "../../utils/apiCall";
import { useNavigate } from "react-router-dom";

const ProfileSection = () => {


  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);


  const navigate = useNavigate(); // Initialize useNavigate hook

  // Function to navigate to the home page
  const handleBackClick = () => {
    navigate("/admin/profile"); // Navigate to the home page (you can modify the path as needed)
  };

  const [formData, setFormData] = useState({
    id: '',
    name: '',
  });

  useEffect(() => {
    getDealerDetails();
  }, []);

  const getDealerDetails = async () => {
    try {
      const response = await getSingleAdminDetails();
      console.log('response is here', response);
      if (response && response.admin) {
        setFormData({
          id: response.admin.id,
          name: response.admin.name,
        });
      }
    } catch (error) {
      console.error('Failed to fetch dealer details', error);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-4 flex flex-col items-center">
      {/* Profile Picture */}
      <div className="relative cursor-pointer"
       onClick={()=>{
        handleBackClick();
       }}
      >
        <img
          src="https://via.placeholder.com/100" // Replace with user's profile image URL
          alt="User Profile"
          className="w-24 h-24 rounded-full object-cover border-4 border-gray-200"
        />
        <div className="absolute bottom-0 right-0 bg-green-500 rounded-full w-4 h-4 border-2 border-white"></div>
      </div>
      {/* User Details */}
      {/* <h2 className="text-lg font-bold mt-2">{formData.name}</h2> */}
      <h2 className="text-lg font-bold mt-2">{formData?.name?.charAt(0).toUpperCase() + formData?.name?.slice(1)}</h2>

      <p className="text-gray-500 text-sm">Admin ID: {formData.id}</p>

      {/* Buttons Section */}
      <div className="flex flex-col gap-4 mt-4 w-full">
        <button className="flex items-center justify-between w-full px-4 py-2 text-white rounded-lg shadow ">
        <FiUserPlus size={20} color="#000" />
          {/* <span>Add Seller</span> */}

          <button
        className="bg-[#e2f2b2] hover:bg-[#3c771a] text-gray-700 hover:text-stone-50 font-semibold py-2 px-4 border-none rounded-md transition duration-200 ease-in-out"
        onClick={handleShow}
      >
        Add Dealer
      </button>

        </button>
      
      </div>
      <AddSellerModal
        show={showModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default ProfileSection;

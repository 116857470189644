
export const validateFields = (formData) => {
  // Check for mandatory fields that should not be empty
  if (!formData.firstName) {
    return "firstName";
  } else if (!formData.lastName) {
    return "lastName";
  } else if (!formData.mobile) {
    return "mobile";
  } else if (!formData.password) {
    return "password";
  } else if (!formData.email) {
    return "email";
  } else if (!formData.shopName) {
    return "shopName";
  } else if (!formData.shopAddress) {
    return "shopAddress";
  } 
  // else if (!formData.gst) {
  //   return "gst number";
  // }
  
  else if (!formData.categoryIds) {
    return "categoryIds";
  } else if (!formData.city) {
    return "city";
  } else if (!formData.state) {
    return "state";
  } else if (!formData.area) {
    return "area";
  } else if (!formData.postalCode) {
    return "postalCode";
  } else if (!formData.country) {
    return "country";
  } 
  // Validate mobile number length
  if (formData.mobile && formData.mobile.length !== 10) {
    return "mobile"; // If mobile number is not 10 digits
  }

  // Check if OTP is verified
  // if (!formData.otpVerified) {
  //   return "otp verification is required"; // OTP verification is required
  // }

  // Validate file uploads (photo, KYC, images, and GST certificate)
 
  // if (!formData.photo) {
  //   return "KYC Document AadharCard"; // If AadharCard photo is not uploaded
  // } 
  // else if (!formData.kyc) {
  //   return "KYC Document PanCard"; // If PanCard is not uploaded
  // } 

  else if (!formData.photo1) {
    return "SignBoard Image"; // If SignBoard Image is not uploaded
  } else if (!formData.photo2) {
    return "Inside front side"; // If Inside front side photo is not uploaded
  } else if (!formData.photo3) {
    return "Inside right side"; // If Inside right side photo is not uploaded
  } else if (!formData.photo4) {
    return "Inside left side"; // If Inside left side photo is not uploaded
  }
  // else if (!formData.gstCertificate) {
  //   return "GST Certificate"; // GST Certificate is mandatory
  // }


  return null; // No missing or invalid fields
};

import { Col, Row } from "react-bootstrap";
import { memo, useEffect } from "react";
import ProductCard from "./ProductCard/ProductCard";

const ShopList = ({ productItems }) => {
  useEffect(() => {}, [productItems]);
  if (productItems.length === 0) {
    return <h1 className="not-found">Product Not Found !!</h1>;
  }
  return (
    <Row className="justify-content-center">
    
      {productItems.map((productItem) => {
        return (
          <Col
          key={productItem.id} 
          xs={12}   // 1 product per row on mobile (extra small screens)
          sm={6}    // 2 products per row on small devices
          md={3}   
        >
          <ProductCard
            key={productItem.id}
            title={null}
            productItem={productItem}
          />
             </Col>
        );
      })}
   
    </Row>
  );
};
export default memo(ShopList);

// src/TermsAndConditions.js
import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 mt-16 pt-20">
      <h1 className="text-3xl font-bold text-center mb-6 text-gray-800">Terms and Conditions</h1>
      <p className="text-lg text-gray-600 mb-8 text-center">
        By accessing or using our services, you agree to be bound by the following terms and conditions.
      </p>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold text-gray-700">1. Inaccurate Pricing</h2>
        <p className="text-lg text-gray-600 mt-2">
          If a business owner uploads inaccurate pricing, their account may be suspended. We recommend verifying the pricing of all products to ensure accuracy.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold text-gray-700">2. Failure to Fulfill Order</h2>
        <p className="text-lg text-gray-600 mt-2">
          If a business owner fails to fulfill an order at the committed rate during the verification process, their account may be suspended. Please ensure timely processing and fulfillment of orders to avoid suspension.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold text-gray-700">3. Selling Duplicate Products</h2>
        <p className="text-lg text-gray-600 mt-2">
          If a business owner attempts to sell duplicate products, their account may be suspended. You must ensure that the products listed are unique and not duplicated across your listings.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold text-gray-700">4. Responsibility for Fraud</h2>
        <p className="text-lg text-gray-600 mt-2">
          The company will not be responsible if a customer is cheated by any seller. Sellers are responsible for maintaining the integrity of their listings and transactions.
        </p>
      </section>

      {/* <footer className="text-center mt-8 text-sm text-gray-500">
        <p>&copy; 2024 Your Company Name. All rights reserved.</p>
      </footer> */}
    </div>
  );
}

export default TermsAndConditions;

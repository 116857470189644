




import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import { MdMyLocation } from "react-icons/md";

const CurrentLocationMarker = ({ position, onDragEnd }) => {
  // Fix for marker icon (default)
  const DefaultIcon = new L.Icon({
    iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
    iconRetinaUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png",
    shadowUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  const map = useMap();

  // Update map's center when position changes
  useEffect(() => {
    if (position) {
      map.setView(position, 15); // Set map center to current position
    }
  }, [position, map]);

  const handleDragEnd = (event) => {
    const newLatLng = event.target.getLatLng();
    onDragEnd(newLatLng); // Notify parent component about the new position
  };

  return position ? (
    <Marker
      position={position}
      draggable={true}
      eventHandlers={{ dragend: handleDragEnd }} // Trigger on drag end
    >
      <Popup>
        <span>Drag me!</span>
      </Popup>
    </Marker>
  ) : null;
};

const CurrentLocationMap = ({onLocationUpdate}) => {
  const [position, setPosition] = useState(null); // User's current location
  const [latLng, setLatLng] = useState({ lat: null, lng: null }); // Track marker position




  const handleGeolocationError = (error) => {
    console.error("Geolocation Error: ", error);
    alert("Please turn on your location services and allow permissions.");
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (location) => {
          const { latitude, longitude } = location.coords;
          setPosition([latitude, longitude]);
          setLatLng({ lat: latitude, lng: longitude });
          onLocationUpdate({ lat: latitude, lng: longitude });
        },
        (error) => {
          handleGeolocationError(error);
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
    }
  }, []);

  
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (location) => {
          const { latitude, longitude } = location.coords;
          setPosition([latitude, longitude]); // Set initial position
          setLatLng({ lat: latitude, lng: longitude }); // Set initial latitude/longitude
        },
        (error) => {
          console.error("Geolocation Error:", error);
          setPosition([28.6139, 77.2090]); // Fallback: New Delhi
          setLatLng({ lat: 28.6139, lng: 77.2090 }); // Fallback
        }
      );
    } else {
      console.error("Geolocation not supported by this browser.");
      setPosition([28.6139, 77.2090]); // Fallback
      setLatLng({ lat: 28.6139, lng: 77.2090 }); // Fallback
    }
  }, []);

  const handleMarkerDragEnd = (newLatLng) => {
    setLatLng({ lat: newLatLng.lat, lng: newLatLng.lng }); // Update latitude and longitude when marker is dragged
  };

  useEffect(()=>{
    onLocationUpdate({ lat: latLng.lat, lng: latLng.lng });
  },[latLng])

  const handleCenterOnCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (location) => {
          const { latitude, longitude } = location.coords;
          setPosition([latitude, longitude]);
          setLatLng({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error re-centering to current location: ", error);
        }
      );
    } else {
      console.error("Geolocation not supported by this browser.");
    }
  };

  return (
    <div>
      <MapContainer
        center={position || [28.6139, 77.2090]} // Fallback center if position is null
        zoom={15}
        style={{ height: "300px", width: "95%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <CurrentLocationMarker position={position} onDragEnd={handleMarkerDragEnd} />
      </MapContainer>

      {/* Center on Current Location Button */}
      <div
        style={{
          padding: "10px",
          textAlign: "center",
          backgroundColor: "#f0f0f0",
          margin: "10px",
        }}
      >
        <button
          onClick={handleCenterOnCurrentLocation}
          style={{
            padding: "10px 20px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          <MdMyLocation style={{ marginRight: "5px" }} />
        </button>
      </div>

      {/* Display Latitude and Longitude */}
    </div>
  );
};

export default CurrentLocationMap;







// import React, { useEffect, useState } from "react";
// import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
// import L from "leaflet";
// import "leaflet/dist/leaflet.css";
// import { MdMyLocation } from "react-icons/md";

// const CurrentLocationMarker = ({ position, onDragEnd }) => {
//   const DefaultIcon = new L.Icon({
//     iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
//     iconRetinaUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png",
//     shadowUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png",
//     iconSize: [25, 41],
//     iconAnchor: [12, 41],
//   });

//   L.Marker.prototype.options.icon = DefaultIcon;

//   const map = useMap();

//   useEffect(() => {
//     if (position) {
//       map.setView(position, 15); // Set map center to current position
//     }
//   }, [position, map]);

//   const handleDragEnd = (event) => {
//     const newLatLng = event.target.getLatLng();
//     onDragEnd(newLatLng); // Notify parent component about the new position
//   };

//   return position ? (
//     <Marker
//       position={position}
//       draggable={true}
//       eventHandlers={{ dragend: handleDragEnd }} // Trigger on drag end
//     >
//       <Popup>
//         <span>Drag me!</span>
//       </Popup>
//     </Marker>
//   ) : null;
// };

// const CurrentLocationMap = ({ onLocationUpdate }) => {
//   const [position, setPosition] = useState(null);
//   const [latLng, setLatLng] = useState({ lat: null, lng: null });
//   const [locationInput, setLocationInput] = useState(""); // State for user input

//   const handleGeolocationError = (error) => {
//     console.error("Geolocation Error: ", error);
//     alert("Please turn on your location services and allow permissions.");
//   };

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (location) => {
//           const { latitude, longitude } = location.coords;
//           setPosition([latitude, longitude]);
//           setLatLng({ lat: latitude, lng: longitude });
//           onLocationUpdate({ lat: latitude, lng: longitude });
//         },
//         (error) => {
//           handleGeolocationError(error);
//         }
//       );
//     } else {
//       alert("Geolocation is not supported by your browser.");
//     }
//   }, []);

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (location) => {
//           const { latitude, longitude } = location.coords;
//           setPosition([latitude, longitude]);
//           setLatLng({ lat: latitude, lng: longitude });
//         },
//         (error) => {
//           console.error("Geolocation Error:", error);
//           setPosition([28.6139, 77.2090]);
//           setLatLng({ lat: 28.6139, lng: 77.2090 });
//         }
//       );
//     } else {
//       console.error("Geolocation not supported by this browser.");
//       setPosition([28.6139, 77.2090]);
//       setLatLng({ lat: 28.6139, lng: 77.2090 });
//     }
//   }, []);

//   const handleMarkerDragEnd = (newLatLng) => {
//     setLatLng({ lat: newLatLng.lat, lng: newLatLng.lng });
//   };

//   const handleCenterOnCurrentLocation = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (location) => {
//           const { latitude, longitude } = location.coords;
//           setPosition([latitude, longitude]);
//           setLatLng({ lat: latitude, lng: longitude });
//         },
//         (error) => {
//           console.error("Error re-centering to current location: ", error);
//         }
//       );
//     } else {
//       console.error("Geolocation not supported by this browser.");
//     }
//   };

//   const handleLocationSearch = async () => {
//     if (!locationInput) return;
//     try {
//       const response = await fetch(
//         `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
//           locationInput
//         )}`
//       );
//       const data = await response.json();
//       if (data.length > 0) {
//         const { lat, lon } = data[0];
//         setPosition([lat, lon]);
//         setLatLng({ lat, lng: lon });
//         onLocationUpdate({ lat, lng: lon });
//       } else {
//         alert("Location not found.");
//       }
//     } catch (error) {
//       console.error("Error fetching location: ", error);
//     }
//   };

//   return (
//     <div>
//       {/* Location input field */}
//       <div style={{ padding: "10px", textAlign: "center" }}>
//         <input
//           type="text"
//           value={locationInput}
//           onChange={(e) => setLocationInput(e.target.value)}
//           placeholder="Enter location"
//           style={{
//             padding: "8px",
//             borderRadius: "5px",
//             width: "70%",
//             marginBottom: "10px",
//           }}
//         />
//         <button
//           onClick={handleLocationSearch}
//           style={{
//             padding: "8px 15px",
//             backgroundColor: "#007bff",
//             color: "white",
//             border: "none",
//             borderRadius: "5px",
//             cursor: "pointer",
//           }}
//         >
//           OK
//         </button>
//       </div>

//       <MapContainer
//         center={position || [28.6139, 77.2090]} // Fallback center if position is null
//         zoom={15}
//         style={{ height: "300px", width: "95%" }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         <CurrentLocationMarker position={position} onDragEnd={handleMarkerDragEnd} />
//       </MapContainer>

//       {/* Center on Current Location Button */}
//       <div
//         style={{
//           padding: "10px",
//           textAlign: "center",
//           backgroundColor: "#f0f0f0",
//           margin: "10px",
//         }}
//       >
//         <button
//           onClick={handleCenterOnCurrentLocation}
//           style={{
//             padding: "10px 20px",
//             backgroundColor: "#007bff",
//             color: "white",
//             border: "none",
//             borderRadius: "5px",
//             cursor: "pointer",
//           }}
//         >
//           <MdMyLocation style={{ marginRight: "5px" }} />
//         </button>
//       </div>

//       {/* Display Latitude and Longitude */}
//       <div style={{ textAlign: "center" }}>
//         <p>
//           Latitude: {latLng.lat}, Longitude: {latLng.lng}
//         </p>
//       </div>
//     </div>
//   );
// };

// export default CurrentLocationMap;




// import React, { useEffect, useState } from "react";
// import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
// import L from "leaflet";
// import "leaflet/dist/leaflet.css";
// import { MdMyLocation } from "react-icons/md";

// const CurrentLocationMarker = ({ position, onDragEnd }) => {
//   const DefaultIcon = new L.Icon({
//     iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
//     iconRetinaUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png",
//     shadowUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png",
//     iconSize: [25, 41],
//     iconAnchor: [12, 41],
//   });

//   L.Marker.prototype.options.icon = DefaultIcon;

//   const map = useMap();

//   useEffect(() => {
//     if (position) {
//       map.setView(position, 15); // Set map center to current position
//     }
//   }, [position, map]);

//   const handleDragEnd = (event) => {
//     const newLatLng = event.target.getLatLng();
//     onDragEnd(newLatLng); // Notify parent component about the new position
//   };

//   return position ? (
//     <Marker
//       position={position}
//       draggable={true}
//       eventHandlers={{ dragend: handleDragEnd }} // Trigger on drag end
//     >
//       <Popup>
//         <span>Drag me!</span>
//       </Popup>
//     </Marker>
//   ) : null;
// };

// const CurrentLocationMap = ({ onLocationUpdate }) => {
//   const [position, setPosition] = useState(null);
//   const [latLng, setLatLng] = useState({ lat: null, lng: null });
//   const [locationInput, setLocationInput] = useState(""); // State for user input

//   const handleGeolocationError = (error) => {
//     console.error("Geolocation Error: ", error);
//     alert("Please turn on your location services and allow permissions.");
//   };

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (location) => {
//           const { latitude, longitude } = location.coords;
//           setPosition([latitude, longitude]);
//           setLatLng({ lat: latitude, lng: longitude });
//           onLocationUpdate({ lat: latitude, lng: longitude });
//         },
//         (error) => {
//           handleGeolocationError(error);
//         }
//       );
//     } else {
//       alert("Geolocation is not supported by your browser.");
//     }
//   }, []);

//   const handleMarkerDragEnd = (newLatLng) => {
//     setLatLng({ lat: newLatLng.lat, lng: newLatLng.lng });
//   };

//   const handleCenterOnCurrentLocation = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (location) => {
//           const { latitude, longitude } = location.coords;
//           setPosition([latitude, longitude]);
//           setLatLng({ lat: latitude, lng: longitude });
//         },
//         (error) => {
//           console.error("Error re-centering to current location: ", error);
//         }
//       );
//     } else {
//       console.error("Geolocation not supported by this browser.");
//     }
//   };

//   const handleLocationSearch = async () => {
//     if (!locationInput) return;

//     // Check if the input is a Google Maps URL
//     const match = locationInput.match(/https:\/\/www\.google\.com\/maps\?q=([\-0-9.]+),([\-0-9.]+)/);
//     if (match) {
//       // Extract the latitude and longitude from the URL
//       const lat = parseFloat(match[1]);
//       const lng = parseFloat(match[2]);

//       // Set position on the map to the extracted coordinates
//       setPosition([lat, lng]);
//       setLatLng({ lat, lng });

//       // You can use reverse geocoding to fetch the address if needed
//       const address = await getAddressFromLatLng(lat, lng);
//       setLocationInput(address); // Update input field with address
//       onLocationUpdate({ lat, lng });
//     } else {
//       alert("Invalid Google Maps URL.");
//     }
//   };

//   // Reverse geocoding function to get address from lat, lng
//   const getAddressFromLatLng = async (lat, lng) => {
//     try {
//       const response = await fetch(
//         `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`
//       );
//       const data = await response.json();
//       return data.display_name || "Unknown address";
//     } catch (error) {
//       console.error("Error fetching address: ", error);
//       return "Unable to fetch address";
//     }
//   };

//   return (
//     <div>
//       {/* Location input field */}
//       <div style={{ padding: "10px", textAlign: "center" }}>
//         <input
//           type="text"
//           value={locationInput}
//           onChange={(e) => setLocationInput(e.target.value)}
//           placeholder="Enter Google Maps URL or address"
//           style={{
//             padding: "8px",
//             borderRadius: "5px",
//             width: "70%",
//             marginBottom: "10px",
//           }}
//         />
//         <button
//           onClick={handleLocationSearch}
//           style={{
//             padding: "8px 15px",
//             backgroundColor: "#007bff",
//             color: "white",
//             border: "none",
//             borderRadius: "5px",
//             cursor: "pointer",
//           }}
//         >
//           OK
//         </button>
//       </div>

//       <MapContainer
//         center={position || [28.6139, 77.2090]} // Fallback center if position is null
//         zoom={15}
//         style={{ height: "300px", width: "95%" }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         <CurrentLocationMarker position={position} onDragEnd={handleMarkerDragEnd} />
//       </MapContainer>

//       {/* Center on Current Location Button */}
//       <div
//         style={{
//           padding: "10px",
//           textAlign: "center",
//           backgroundColor: "#f0f0f0",
//           margin: "10px",
//         }}
//       >
//         <button
//           onClick={handleCenterOnCurrentLocation}
//           style={{
//             padding: "10px 20px",
//             backgroundColor: "#007bff",
//             color: "white",
//             border: "none",
//             borderRadius: "5px",
//             cursor: "pointer",
//           }}
//         >
//           <MdMyLocation style={{ marginRight: "5px" }} />
//         </button>
//       </div>

//       {/* Display Latitude and Longitude */}
//       <div style={{ textAlign: "center" }}>
//         <p>
//           Latitude: {latLng.lat}, Longitude: {latLng.lng}
//         </p>
//       </div>
//     </div>
//   );
// };

// export default CurrentLocationMap;





// import React, { useEffect, useState } from "react";
// import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
// import L from "leaflet";
// import "leaflet/dist/leaflet.css";
// import { MdMyLocation } from "react-icons/md";

// const CurrentLocationMarker = ({ position, onDragEnd }) => {
//   const DefaultIcon = new L.Icon({
//     iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
//     iconRetinaUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png",
//     shadowUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png",
//     iconSize: [25, 41],
//     iconAnchor: [12, 41],
//   });

//   L.Marker.prototype.options.icon = DefaultIcon;

//   const map = useMap();

//   useEffect(() => {
//     if (position) {
//       map.setView(position, 15);
//     }
//   }, [position, map]);

//   const handleDragEnd = (event) => {
//     const newLatLng = event.target.getLatLng();
//     onDragEnd(newLatLng);
//   };

//   return position ? (
//     <Marker
//       position={position}
//       draggable={true}
//       eventHandlers={{ dragend: handleDragEnd }}
//     >
//       <Popup>
//         <span>Drag me!</span>
//       </Popup>
//     </Marker>
//   ) : null;
// };

// const CurrentLocationMap = ({ onLocationUpdate }) => {
//   const [position, setPosition] = useState(null);
//   const [latLng, setLatLng] = useState({ lat: null, lng: null });
//   const [locationInput, setLocationInput] = useState("");

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (location) => {
//           const { latitude, longitude } = location.coords;
//           setPosition([latitude, longitude]);
//           setLatLng({ lat: latitude, lng: longitude });
//           onLocationUpdate({ lat: latitude, lng: longitude });
//         },
//         (error) => {
//           console.error("Geolocation Error: ", error);
//         }
//       );
//     }
//   }, []);

//   const handleMarkerDragEnd = (newLatLng) => {
//     setLatLng({ lat: newLatLng.lat, lng: newLatLng.lng });
//     onLocationUpdate({ lat: newLatLng.lat, lng: newLatLng.lng });
//   };

//   const handleLocationSearch = async () => {
//     if (!locationInput) return;

//     // Check if input is a Google Maps URL with complex structure
//     const googleComplexMatch = locationInput.match(/@([\d.-]+),([\d.-]+)/);
//     if (googleComplexMatch) {
//       const lat = parseFloat(googleComplexMatch[1]);
//       const lng = parseFloat(googleComplexMatch[2]);
//       updateMapAndSendLocation(lat, lng);
//       return;
//     }

//     // Check if input is a Google Maps URL with simple coordinates
//     const googleSimpleMatch = locationInput.match(/q=([\d.-]+),([\d.-]+)/);
//     if (googleSimpleMatch) {
//       const lat = parseFloat(googleSimpleMatch[1]);
//       const lng = parseFloat(googleSimpleMatch[2]);
//       updateMapAndSendLocation(lat, lng);
//       return;
//     }

//     // Check if input is plain coordinates
//     const plainCoordinatesMatch = locationInput.match(/^([\d.-]+),\s*([\d.-]+)$/);
//     if (plainCoordinatesMatch) {
//       const lat = parseFloat(plainCoordinatesMatch[1]);
//       const lng = parseFloat(plainCoordinatesMatch[2]);
//       updateMapAndSendLocation(lat, lng);
//       return;
//     }

//     // Handle city/state name search
//     const geocodedData = await geocodeLocation(locationInput);
//     if (geocodedData) {
//       const { lat, lng } = geocodedData;
//       updateMapAndSendLocation(lat, lng);
//     } else {
//       alert("Location not found. Please check your input.");
//     }
//   };

//   const updateMapAndSendLocation = (lat, lng) => {
//     setPosition([lat, lng]);
//     setLatLng({ lat, lng });
//     onLocationUpdate({ lat, lng });
//   };

//   const geocodeLocation = async (query) => {
//     try {
//       const response = await fetch(
//         `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
//           query
//         )}&format=json&limit=1`
//       );
//       const data = await response.json();
//       if (data && data.length > 0) {
//         const { lat, lon } = data[0];
//         return { lat: parseFloat(lat), lng: parseFloat(lon) };
//       }
//       return null;
//     } catch (error) {
//       console.error("Error geocoding location: ", error);
//       return null;
//     }
//   };

//   return (
//     <div>
//       <div style={{ padding: "10px", textAlign: "center" }}>
//         <input
//           type="text"
//           value={locationInput}
//           onChange={(e) => setLocationInput(e.target.value)}
//           placeholder="Enter Google Maps URL, coordinates, or city/state"
//           style={{
//             padding: "8px",
//             borderRadius: "5px",
//             width: "70%",
//             marginBottom: "10px",
//             border:5,
//             borderWidth:2,
//             borderColor:"black"
//           }}
//         />
//         <button
//           onClick={handleLocationSearch}
//           style={{
//             padding: "8px 15px",
//             backgroundColor: "#007bff",
//             color: "white",
//             border: "none",
//             borderRadius: "5px",
//             cursor: "pointer",
//           }}
//         >
//           OK
//         </button>
//       </div>

//       <MapContainer
//         center={position || [28.6139, 77.2090]} // Fallback center if position is null
//         zoom={15}
//         style={{ height: "300px", width: "95%" }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         <CurrentLocationMarker position={position} onDragEnd={handleMarkerDragEnd} />
//       </MapContainer>

//       <div
//         style={{
//           padding: "10px",
//           textAlign: "center",
//           backgroundColor: "#f0f0f0",
//           margin: "10px",
//         }}
//       >
//         <button
//           onClick={() => {
//             if (navigator.geolocation) {
//               navigator.geolocation.getCurrentPosition(
//                 ({ coords }) => updateMapAndSendLocation(coords.latitude, coords.longitude),
//                 (error) => console.error("Error re-centering: ", error)
//               );
//             }
//           }}
//           style={{
//             padding: "10px 20px",
//             backgroundColor: "#007bff",
//             color: "white",
//             border: "none",
//             borderRadius: "5px",
//             cursor: "pointer",
//           }}
//         >
//           <MdMyLocation style={{ marginRight: "5px" }} />
//         </button>
//       </div>

//       <div style={{ textAlign: "center" }}>
//         <p>
//           Latitude: {latLng.lat}, Longitude: {latLng.lng}
//         </p>
//       </div>
//     </div>
//   );
// };

// export default CurrentLocationMap;

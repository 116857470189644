

import { Fragment, useState, useEffect } from "react";
import Wrapper from "../components/wrapper/Wrapper";
import Section from "../components/Section";
import SliderHome from "../components/Slider";
import useWindowScrollToTop from "../hooks/useWindowScrollToTop";
import { axiosInstance } from "../utils/axios";
import { useLocation, useNavigate } from "react-router-dom";
import QuerySubmit from "./QuerySubmit";
import DistanceFilter from '../components/HomePage/DistanceFilter';
import UserRegisterModal from "../components/user/userRegister";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import OTPVerification from "../components/HomePage/OtpVerification";
import { requestLocation } from "../utils/apiCall";
import LocationPermissionModal from "../components/Navbar/locationPermissionModal";

const Home = () => {
  const buyer = useSelector((state) => state.buyer.buyer);

  const dispatch = useDispatch();
  const router = useNavigate();

  const [isModalVisible, setModalVisible] = useState(false);  // To control modal visibility
  const [isProductAdded, setProductAdded] = useState(false);  // To track if product is added to cart

  // Close the modal
  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleRegister = (name, mobile, email) => {
    // Here you can send the registration details to your backend
    console.log("User Registered", { name, mobile, email });
    toast.success("Successfully registered!");
  };


  const location = useLocation(); // Get the current URL

  const [searchQuery, setSearchQuery] = useState(""); // Store searchQuery in state
  const [discoutProducts, setDiscoutProducts] = useState([]);
  const [bestSales, setBestSales] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state to handle fetching

  const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(1);

// const [page,setPage] = useState(1);

  const [filterParams, setFilterParams] = useState({
    distance: '',
    city: '',
    state: '',
    country: ''
  });

  // Fetch products when the query changes or the component mounts
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("query");
    const latitude = queryParams.get("latitude");
    const longitude = queryParams.get("longitude");

    if(query && buyer != 'buyer'){
      setModalVisible(true)
  }

    if (!query) {
      fetchProducts("", "", "", "", "", "");
    } else {
      fetchProducts(query, latitude, longitude);
    }

    setSearchQuery(query || ""); // Update searchQuery state with the query from URL
  }, [location.search]);



   const navigate = useNavigate()
  
      const [locationPermissionModalOpen, setLocationPermissionModalOpen] = useState(false);
    
      const handleModalClose = () => {
        setLocationPermissionModalOpen(false);
      };

    const handleSearch = async () => {
        try {
          const position = await requestLocation();
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          return { latitude, longitude };
          // navigate(`/&latitude=${latitude}&longitude=${longitude}`);
        } catch (error) {
          setLocationPermissionModalOpen(true);
        }
      };

  const fetchProducts = async (searchQuery, latitude, longitude, distance, city, state, country,page = 1) => {
    try {
      setLoading(true);

      const result = await handleSearch();
      let lat,long;
      if (result) {
       lat = result.latitude
       long= result.longitude
      }
      const response = await axiosInstance.get(`/search/products`, {
        params: {
          query: searchQuery,
          limit: 50,
          page: page,
          userLat: lat ? lat : latitude,
          userLng: long ? long :longitude,
          distance: distance,
          city: city,
          state: state,
          country: country
        },
      });

      const products = response.data.products;
      console.log('Products fetched:', products);
      setTotalPages(response.data.totalPages || 1);
      setDiscoutProducts(products); // Example filter for discount products
      setBestSales(products); // Example for best sales
      setLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      setLoading(false); // Set loading to false in case of error
    }
  };


  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      fetchProducts("", "", "", "", "", "", "", newPage);
    }
  };
  
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage);
      fetchProducts("", "", "", "", "", "", "", newPage);
    }
  };

  // Handle filter changes
  const onFilterChange = (distance, city, state, country) => {
    setFilterParams({
      distance,
      city,
      state,
      country
    });

    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("query");
    const latitude = queryParams.get("latitude");
    const longitude = queryParams.get("longitude");

    console.log('console data ',  distance,
      city,
      state,
      country,
    )

    fetchProducts(query, latitude, longitude, distance, city, state, country);
  };

  useWindowScrollToTop();

  return (
    <div>
    <Fragment>
      <div
      //  style={{marginTop:20}}
      >
      {/* Only show SliderHome and Wrapper when searchQuery is empty */}
      {/* {searchQuery && */}
       <DistanceFilter onFilterChange={onFilterChange} /> 
       {/* } */}
      <Section
        title=""
        bgColor="#d6e5fb"
        productItems={discoutProducts}
      />
      <div className="pagination-controls flex justify-center items-center gap-4 mt-4">
  {/* Previous Button */}
  <button
    disabled={currentPage <= 1}
    onClick={handlePreviousPage}
    className={`px-4 py-2 rounded-lg border transition duration-200 ease-in-out ${
      currentPage <= 1
        ? "bg-blue-400 opacity-50 cursor-not-allowed"
        : "bg-blue-500 hover:bg-blue-600"
    }`}
  >
    Previous
  </button>

  {/* Pagination Info */}
  <span className="text-gray-700 font-medium">
    Page {currentPage} of {totalPages}
  </span>

  {/* Next Button */}
  <button
    disabled={currentPage >= totalPages}
    onClick={handleNextPage}
    className={`px-4 py-2 rounded-lg border transition duration-200 ease-in-out ${
      currentPage >= totalPages
        ? "bg-blue-400 opacity-50 cursor-not-allowed"
        : "bg-blue-500 hover:bg-blue-600"
    }`}
  >
    Next
  </button>
</div>


      </div>
       <OTPVerification
      isOpen={isModalVisible} 
      onClose={handleCloseModal} 
       />

<LocationPermissionModal
        show={locationPermissionModalOpen}
        handleClose={handleModalClose}
        // handleRetry={handleRetry}
      />

    </Fragment>
    </div>
  );
};

export default Home;






// import { Fragment, useState, useEffect } from "react";
// import Wrapper from "../components/wrapper/Wrapper";
// import Section from "../components/Section";
// import SliderHome from "../components/Slider";
// import useWindowScrollToTop from "../hooks/useWindowScrollToTop";
// import { axiosInstance } from "../utils/axios";
// import { useLocation } from "react-router-dom";
// import "./Modal.css"; // Custom modal styles
// import QuerySubmit from "./QuerySubmit";

// const Home = () => {
//   const location = useLocation(); // Get the current URL

//   const [searchQuery, setSearchQuery] = useState(""); // Store searchQuery in state
//   const [discoutProducts, setDiscoutProducts] = useState([]);
//   const [bestSales, setBestSales] = useState([]);
//   const [loading, setLoading] = useState(true); // Loading state to handle fetching
//   const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
//   const [userChoice, setUserChoice] = useState(
//     localStorage.getItem("locationPermission") // Retrieve user choice from localStorage
//   );

//   const [userLocation, setUserLocation] = useState({
//     latitude: null,
//     longitude: null,
//   }); // Store user's latitude and longitude

//   // Fetch products when the query changes or the component mounts
//   useEffect(() => {
//     const queryParams = new URLSearchParams(location.search);
//     const query = queryParams.get("query");

//     setSearchQuery(query || ""); // Update searchQuery state with the query from URL
 
//    if(!query){
//     fetchProducts("","","")
//    }
    
//     // Open the modal only if there's a query and no prior choice from the user
//     if (query && !userChoice) {
//       setIsModalOpen(true);
//     }
//   }, [location.search, userChoice]);

//   // Handle user response to modal
//   const handleModalResponse = (response) => {
//     if (response === "yes" && navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           setUserLocation({
//             latitude: position.coords.latitude,
//             longitude: position.coords.longitude,
//           });
//           localStorage.setItem("locationPermission", "granted"); // Save user choice
//           setUserChoice("granted");
//           setIsModalOpen(false); // Close the modal
//         },
//         (error) => {
//           console.error("Geolocation error:", error);
//           localStorage.setItem("locationPermission", "denied"); // Save user choice
//           setUserChoice("denied");
//           setIsModalOpen(false); // Close the modal on error
//         }
//       );
//     } else {
//       console.log("User denied location permission.");
//       localStorage.setItem("locationPermission", "denied"); // Save user choice
//       setUserChoice("denied");
//       setIsModalOpen(false); // Close the modal if "No" is clicked
//     }
//   };

//   // Fetch products from the API
//   useEffect(() => {
//     if (userLocation.latitude && userLocation.longitude) {
//       fetchProducts(searchQuery, userLocation.latitude, userLocation.longitude);
//     }
//   }, [searchQuery, userLocation]); // Re-run fetchProducts when searchQuery or userLocation changes

//   const fetchProductsq = async (searchQuery, latitude, longitude) => {
//     alert('wrkog')
//     try {
//       setLoading(true);
//       const response = await axiosInstance.get(`/search/products`, {
//         params: {
//           query: searchQuery,
//           limit: 10,
//           page: 1,
//           userLat: latitude,
//           userLng: longitude,
//         },
//       });

//       const products = response.data.products;

//       setDiscoutProducts(products.filter((item) => item.quantity <= 500)); // Example filter for discount products
//       setBestSales(products.filter((item) => item.quantity > 0)); // Example for best sales
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching products:", error);
//       setLoading(false); // Set loading to false in case of error
//     }
//   };

//   const fetchProducts = async (searchQuery, latitude, longitude) => {
//     try {
//       setLoading(true);
  
//       const params = {
//         limit: 10,
//         page: 1,
//         userLat: latitude,
//         userLng: longitude,
//       };
  
//       // Query only if searchQuery exists
//       if (searchQuery) {
//         params.query = searchQuery;
//       }
  
//       const response = await axiosInstance.get(`/search/products`, { params });
  
//       const products = response.data.products;
//       console.log('all products',products);
  
//       setDiscoutProducts(products.filter((item) => item.quantity <= 500)); // Example filter for discount products
//       setBestSales(products.filter((item) => item.quantity > 0)); // Example for best sales
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching products:", error);
//       setLoading(false); // Set loading to false in case of error
//     }
//   };
  

//   useWindowScrollToTop();

//   return (
//     <Fragment>
//       {/* Modal for location permission */}
//       {isModalOpen && (
//        <div className="modal">
//        <div className="modal-content">
//          <h3>Enable Location Access</h3>
//          <p>To enhance your shopping experience, we need access to your location. By sharing your location, we'll show you nearby products, making it easier for you to visit shops and make a purchase.</p>
//          <div className="modal-actions">
//            <button onClick={() => handleModalResponse("yes")}>Allow Location</button>
//            <button onClick={() => handleModalResponse("no")}>Deny</button>
//          </div>
//        </div>
//      </div>
     
//       )}

//       {/* Only show SliderHome and Wrapper when searchQuery is empty */}
//       {!searchQuery && (
//         <>
//           <SliderHome />
//           <Wrapper />
//         </>
//       )}
//  {
//   searchQuery && discoutProducts.length == 0 && 
//  <QuerySubmit/>
// }
//       <Section
//         title="Big Discount"
//         bgColor="#f6f9fc"
//         productItems={discoutProducts}
//       />
//       <Section
//         title="Best Sales"
//         bgColor="#f6f9fc"
//         productItems={bestSales}
//       />
//     </Fragment>
//   );
// };

// export default Home;







// import { Fragment, useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { useGeolocated } from "react-geolocated";
// import Wrapper from "../components/wrapper/Wrapper";
// import Section from "../components/Section";
// import SliderHome from "../components/Slider";
// import QuerySubmit from "./QuerySubmit";
// import useWindowScrollToTop from "../hooks/useWindowScrollToTop";
// import { axiosInstance } from "../utils/axios";
// import "./Modal.css";

// const Home = () => {
//   const location = useLocation(); // Get the current URL
//   const [searchQuery, setSearchQuery] = useState(""); // Store searchQuery in state
//   const [discoutProducts, setDiscoutProducts] = useState([]);
//   const [bestSales, setBestSales] = useState([]);
//   const [loading, setLoading] = useState(true); // Loading state
//   const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state

//   // Using react-geolocated for geolocation
//   const {
//     coords, // Contains latitude and longitude if allowed
//     isGeolocationAvailable, // True if the browser supports geolocation
//     isGeolocationEnabled, // True if user has enabled geolocation
//     getPosition, // Function to trigger manual location fetching
//   } = useGeolocated({
//     positionOptions: {
//       enableHighAccuracy: true,
//     },
//     userDecisionTimeout: 5000, // Timeout if user takes too long
//   });

//   useWindowScrollToTop();

//   // Extract the query from the URL
//   useEffect(() => {
//     const queryParams = new URLSearchParams(location.search);
//     const query = queryParams.get("query");
//     setSearchQuery(query || "");

//     // Open modal if query exists and geolocation isn't enabled
//     if (query) {
//       // alert(query)
//       if(!isGeolocationEnabled){
//       setIsModalOpen(true);
//       }
//     } else if (!query) {
//       fetchProducts("", "", ""); // Fetch all products if no query is present
//     }
//   }, [location.search, isGeolocationEnabled]);

//   // Fetch products from the API
//   const fetchProducts = async (searchQuery, latitude, longitude) => {
//     try {
//       setLoading(true);

//       const params = {
//         limit: 10,
//         page: 1,
//         userLat: latitude || "",
//         userLng: longitude || "",
//       };

//       if (searchQuery) {
//         params.query = searchQuery;
//       }

//       const response = await axiosInstance.get(`/search/products`, { params });
//       const products = response.data.products;

//       setDiscoutProducts(products.filter((item) => item.quantity <= 500)); // Example filter for discount products
//       setBestSales(products.filter((item) => item.quantity > 0)); // Example for best sales
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching products:", error);
//       setLoading(false); // Set loading to false in case of error
//     }
//   };

//   // Trigger fetchProducts whenever location or search query changes
//   useEffect(() => {
//     const latitude = coords?.latitude || "";
//     const longitude = coords?.longitude || "";

//     fetchProducts(searchQuery, latitude, longitude);
//   }, [searchQuery, coords]);

//   // Handle modal response for location permission
//   const handleModalResponse = (response) => {
//     if (response === "yes") {
//       getPosition(); // Trigger geolocation manually
//     }
//     setIsModalOpen(false); // Close modal regardless of response
//   };



//   return (
//     <Fragment>
//       {/* Modal for location permission */}
//       {isModalOpen && (
//         <div className="modal">
//           <div className="modal-content">
//             <h3>Enable Location Access</h3>
//             <p>
//               To enhance your shopping experience, we need access to your location.
//               By sharing your location, we'll show you nearby products.
//             </p>
//             <div className="modal-actions">
//               <button onClick={() => handleModalResponse("yes")}>Allow Location</button>
//               <button onClick={() => handleModalResponse("no")}>Deny</button>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Only show SliderHome and Wrapper when searchQuery is empty */}
//       {!searchQuery && (
//         <>
//           <SliderHome />
//           <Wrapper />
//         </>
//       )}

//       {searchQuery && discoutProducts.length === 0 && <QuerySubmit />}

//       <Section
//         title="Big Discount"
//         bgColor="#f6f9fc"
//         productItems={discoutProducts}
//       />
//       <Section
//         title="Best Sales"
//         bgColor="#f6f9fc"
//         productItems={bestSales}
//       />
//     </Fragment>
//   );
// };

// export default Home;



import React, { useState, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { AiOutlineCamera, AiOutlineClose, AiOutlinePicture, AiOutlineRotateRight } from 'react-icons/ai';
import { canvasPreview } from './CropImageCanva';

export default function ProductImageUpload({ onImageChange ,keyName}) {
  const imgRef = useRef(null);
  const fileInputRefCamera = useRef(null);  // Camera file input reference
  const fileInputRefGallery = useRef(null);  // Gallery file input reference
  const [crop, setCrop] = useState({ unit: '%', width: 30, height: 30, x: 25, y: 25 });
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const [height, setHeight] = useState('');
  const [width, setWidth] = useState('');
  const [completedCrop, setCompletedCrop] = useState();
  const [imageUrl, setImageUrl] = useState(null);
  const [showCroppedImage, setShowCroppedImage] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isCameraActive, setIsCameraActive] = useState(null); // null: not selected, true: camera, false: gallery
  const [image, setImage] = useState(null);

  // Handle file change (when user selects an image from gallery or camera)
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImageUrl(event.target.result);
        setShowCroppedImage(false);
      };
      reader.readAsDataURL(file);
    } else {
      alert('Please select a valid image.');
    }
  };

  // Handle Camera Mode
  const handleCameraClick = () => {
    setIsCameraActive(true);
    fileInputRefCamera.current.click();  // Open camera file picker
  };

  // Handle Gallery Mode
  const handleGalleryClick = () => {
    setIsCameraActive(false);
    fileInputRefGallery.current.click();  // Open gallery file picker
  };

  // Handle zoom (scaling)
  const onZoom = (e) => {
    setScale(parseFloat(e));
  };

  // Handle rotate image
  const rotateRight = () => {
    let newRotation = rotation + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    setRotation(newRotation);
  };

  // Save cropped image
  const saveImage = async () => {
    if (completedCrop) {
      setIsSaving(true); // Show loader
      const croppedImageBlob = await canvasPreview(imgRef.current, completedCrop, scale, rotation);
      const imageUrl = URL.createObjectURL(croppedImageBlob);
      setCroppedImage(imageUrl);
      setShowCroppedImage(true);
      setIsSaving(false); // Hide loader
      // onImageChange(croppedImageBlob);
      onImageChange(croppedImageBlob, keyName);
    }
  };

  // Remove image
  const removeImage = () => {
    setCroppedImage(null);
    setShowCroppedImage(false);
    setImageUrl(null);
    onImageChange('removed',keyName);
  };

  // Handle image load to get height and width
  const onImageLoad = (e) => {
    setHeight(e?.currentTarget?.height);
    setWidth(e?.currentTarget?.width);
    setCompletedCrop({
      x: 0,
      y: 0,
      height: e?.currentTarget?.height,
      width: e?.currentTarget?.width,
      unit: 'px',
    });
  };

  return (
    <div className="outerDiv">
      {/* Camera and Gallery Selection */}
      {!showCroppedImage && (
       <>
      {/* <div className="selectionIcons flex space-x-4 mb-4 mt-3">
        <button
          className={`cameraButton flex items-center space-x-1 px-2 py-1 rounded-lg ${isCameraActive === true ? 'bg-blue-300' : 'bg-blue-100'} text-blue-500 border border-blue-500`}
          onClick={handleCameraClick}
        >
          <AiOutlineCamera className="text-xl" />
          <span>Camera</span>
        </button>
        <button
          className={`galleryButton flex items-center space-x-1 px-2 py-1 rounded-lg ${isCameraActive === false ? 'bg-green-300' : 'bg-green-100'} text-green-500 border border-green-500`}
          onClick={handleGalleryClick}
        >
          <AiOutlinePicture className="text-xl" />
          <span>Gallery</span>
        </button>
      </div> */}


<div className="selectionIcons flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 mb-4 mt-3">
  <button
    className={`cameraButton flex items-center justify-center space-x-1 px-4 py-2 rounded-lg ${isCameraActive === true ? 'bg-blue-300' : 'bg-blue-100'} text-blue-500 border border-blue-500 w-full sm:w-auto`}
    onClick={handleCameraClick}
  >
    <AiOutlineCamera className="text-xl" />
    <span>Camera</span>
  </button>
  <button
    className={`galleryButton flex items-center justify-center space-x-1 px-4 py-2 rounded-lg ${isCameraActive === false ? 'bg-green-300' : 'bg-green-100'} text-green-500 border border-green-500 w-full sm:w-auto`}
    onClick={handleGalleryClick}
  >
    <AiOutlinePicture className="text-xl" />
    <span>Gallery</span>
  </button>
</div>


   

      {/* Hidden File Input for Camera */}
      <input
        type="file"
        ref={fileInputRefCamera}
        style={{ display: 'none' }}
        accept="image/*"
        capture="camera"  // Camera input
        onChange={handleFileChange}
      />

      {/* Hidden File Input for Gallery */}
      <input
        type="file"
        ref={fileInputRefGallery}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleFileChange}
      />

      {/* Image Crop Area */}
      {/* Controls */}
      {imageUrl && (
        <>
       
  <div className="relative">
    {/* Remove Icon */}
    <AiOutlineClose
      className="text-black bg-white rounded-full p-1 cursor-pointer shadow-md hover:bg-gray-200 transition-all"
      size={24}
      onClick={()=> {removeImage()}}
    />
    {/* Image Crop Area */}
    <ReactCrop
      src={imageUrl}
      crop={crop}
      onChange={(_, percentCrop) => {
        setCrop(percentCrop);
      }}
      onComplete={(e) => {
        if (e?.height === 0 || e?.width === 0) {
          setCompletedCrop({
            x: 0,
            y: 0,
            height: height,
            width: width,
            unit: 'px',
          });
        } else {
          setCompletedCrop(e);
        }
      }}
    >
      <img
        className="h-80 w-auto"
        ref={imgRef}
        crossOrigin="anonymous"
        alt="Error"
        src={imageUrl}
        style={{ transform: `scale(${scale}) rotate(${rotation}deg)` }}
        onLoad={onImageLoad}
      />
    </ReactCrop>
  </div>



          <div className="croppedImageContainer flex flex-col items-center space-y-4 p-4">
            <div className="controlsWrapper flex items-center space-x-4">
              <div>
                <AiOutlineRotateRight
                  className="icon text-xl cursor-pointer"
                  onClick={rotateRight}
                />
              </div>

              <div className="saveButtonWrapper">
                <button
                  className="saveButton bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-all duration-300"
                  onClick={saveImage}
                  disabled={isSaving}
                >
                  {isSaving ? (
                    <div className="flex items-center space-x-2">
                      <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                      <span>Saving...</span>
                    </div>
                  ) : (
                    'Save'
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      )}

</>
      )}
      
      {/* {showCroppedImage && (
        <div className="croppedImageContainer flex flex-col items-center space-y-4 p-4">
          <img
            src={croppedImage}
            alt="Cropped"
            className="croppedImage max-w-full h-auto rounded-lg shadow-md"
          />
          <div className="croppedImageActions flex space-x-4">
            <button
              className="removeButton bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 transition-all duration-300"
              onClick={removeImage}
            >
              Remove
            </button>
          </div>
        </div>
      )} */}


{showCroppedImage && (
  <div className="croppedImageContainer flex flex-col items-center space-y-4 p-4">
    <div className="croppedImageWrapper w-40 h-40 overflow-hidden rounded-lg shadow-md">
      <img
        src={croppedImage}
        alt="Cropped"
        className="croppedImage w-full h-full object-cover"
      />
    </div>
    <div className="croppedImageActions flex space-x-4">
      <button
        className="removeButton bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 transition-all duration-300"
        onClick={removeImage}
      >
        Remove
      </button>
    </div>
  </div>
)}



    </div>
  );
}

// import React, { useState } from "react";
// import { toast } from "react-toastify";
// import { sendOtp ,verifyOtp} from "../../../utils/apiCall";

// const OtpVerificationModal = ({ showModal, onClose, mobile }) => {
//   const [otp, setOtp] = useState("");
//   const [otpSent, setOtpSent] = useState(false);
//   const [loading, setLoading] = useState(false);

//   const handleSendOtp = async () => {
//     if (!mobile) {
//       toast.error("Mobile number is required to send OTP.");
//       return;
//     }

//     setLoading(true);
//     try {
//       const response = await sendOtp(mobile);
//       if (response.status === 200) {
//         setOtpSent(true);
//         toast.success("OTP sent successfully.");
//       } else {
//         toast.error("Failed to send OTP.");
//       }
//     } catch (error) {
//       console.error("Error sending OTP:", error);
//       toast.error("Error sending OTP.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleVerifyOtp = async () => {
//     if (!otp) {
//       toast.error("Please enter the OTP.");
//       return;
//     }

//     setLoading(true);
//     try {
//       const response = await verifyOtp(mobile, otp);
//       if (response.status === 200) {
//         toast.success("OTP verified successfully.");
//         setOtpSent(false)
//         onClose({ success: true }); // Notify parent component of success
//       } else {
//         toast.error("Invalid OTP. Please try again.");
//       }
//     } catch (error) {
//       console.error("Error verifying OTP:", error);
//       toast.error("Error verifying OTP.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className={`fixed inset-0 flex items-center justify-center z-50 ${showModal ? "block" : "hidden"}`}>
//       <div className="absolute inset-0 bg-black opacity-50" onClick={() => onClose({ success: false })}></div>
//       <div className="bg-white rounded-lg max-w-md mx-auto p-6 z-10 relative">
//         <div className="flex justify-between items-center">
//           <h2 className="text-xl font-semibold ml-4 mr-5">OTP Verification</h2>
//           <button className="text-gray-600" onClick={() => onClose({ success: false })}>
//             &#10005;
//           </button>
//         </div>
//         <div>
//           {!otpSent ? (
//             <button
//               type="button"
//               className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
//               onClick={handleSendOtp}
//               disabled={loading}
//             >
//               {loading ? "Sending OTP..." : "Send OTP"}
//             </button>
//           ) : (
//             <>
//               <div className="mb-4">
//                 <label className="block text-sm font-medium mb-2" htmlFor="otp">Enter OTP</label>
//                 <input
//                   type="text"
//                   id="otp"
//                   value={otp}
//                   onChange={(e) => setOtp(e.target.value)}
//                   className="w-full p-2 border rounded"
//                   placeholder="Enter OTP"
//                 />
//               </div>
//               <button
//                 type="button"
//                 className="w-full py-2 bg-green-500 text-white rounded hover:bg-green-600"
//                 onClick={handleVerifyOtp}
//                 disabled={loading}
//               >
//                 {loading ? "Verifying OTP..." : "Verify OTP"}
//               </button>
//             </>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default OtpVerificationModal;








import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { sendOtp, verifyOtp } from "../../../utils/apiCall";

const OtpVerificationModal = ({ showModal, onClose, mobile }) => {
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);

  // Automatically send OTP when the modal is shown
  useEffect(() => {
    if (showModal && mobile) {
      handleSendOtp(); // Send OTP when modal opens
    }
  }, [showModal, mobile]);

  const handleSendOtp = async () => {
    if (!mobile) {
      toast.error("Mobile number is required to send OTP.");
      return;
    }

    setLoading(true);
    try {
      const response = await sendOtp(mobile);
      if (response.status === 200) {
        setOtpSent(true);
        toast.success("OTP sent successfully.");
      } else {
        toast.error("Failed to send OTP.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("Error sending OTP.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp) {
      toast.error("Please enter the OTP.");
      return;
    }

    setLoading(true);
    try {
      const response = await verifyOtp(mobile, otp);
      if (response.status === 200) {
        toast.success("OTP verified successfully.");
        setOtpSent(false);
        onClose({ success: true }); // Notify parent component of success
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Error verifying OTP.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`fixed inset-0 flex items-center justify-center z-50 ${showModal ? "block" : "hidden"}`}>
      <div className="absolute inset-0 bg-black opacity-50" onClick={() => onClose({ success: false })}></div>
      <div className="bg-white rounded-lg max-w-md mx-auto p-6 z-10 relative">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold ml-4 mr-5">OTP Verification</h2>
          <button className="text-gray-600" onClick={() => onClose({ success: false })}>
            &#10005;
          </button>
        </div>
        <div>
         
            <>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2" htmlFor="otp">Enter OTP</label>
                <input
                  type="number"
                  id="otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className="w-full p-2 border rounded"
                  placeholder="Enter OTP"
                />
              </div>
              <button
                type="button"
                className="w-full py-2 bg-green-500 text-white rounded hover:bg-green-600"
                onClick={handleVerifyOtp}
                disabled={loading}
              >
                {loading ? "Verifying OTP..." : "Verify OTP"}
              </button>
            </>
          
        </div>
      </div>
    </div>
  );
};

export default OtpVerificationModal;

import React, { useEffect, useState } from "react";
import { FaGift, FaCopy, FaCheck } from "react-icons/fa";

const CouponPopup = ({ couponCode, discountPercent, validity, expiryDate, onClose }) => {
  const [timeRemaining, setTimeRemaining] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const updateRemainingTime = () => {
      const now = new Date();
      const expiration = new Date(expiryDate);
      const timeDiff = expiration - now;

      if (timeDiff > 0) {
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
      } else {
        setTimeRemaining("Expired");
      }
    };

    const timer = setInterval(updateRemainingTime, 1000);

    return () => clearInterval(timer);
  }, [expiryDate]);

  const handleCopy = () => {
    navigator.clipboard.writeText(couponCode);
    setIsCopied(true);

    // Reset copied state after 2 seconds
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
        >
          ✖
        </button>
        <div className="flex items-center space-x-4">
          <div className="text-blue-500">
            <FaGift size={40} />
          </div>
          <div>
            <h2 className="text-xl font-bold">Save {discountPercent}%</h2>
            <p className="text-gray-600">Valid for {validity}</p>
          </div>
        </div>
        <div className="mt-4">
          <p className="text-gray-800">A free coupon for you</p>
          <div className="flex justify-between items-center mt-2 bg-blue-100 rounded-lg px-4 py-2">
            <span className="text-blue-600 font-bold">{couponCode}</span>
            <button
              onClick={handleCopy}
              className="flex items-center text-blue-500 font-semibold space-x-2 hover:text-blue-700"
            >
              {isCopied ? (
                <>
                  <FaCheck className="text-green-500" />
                  <span>Copied!</span>
                </>
              ) : (
                <>
                  <FaCopy />
                  <span>Copy</span>
                </>
              )}
            </button>
          </div>
        </div>
        <div className="mt-4 text-gray-500">
          <p>Expires at: {new Date(expiryDate).toLocaleString()}</p>
          <p className="text-red-500 font-semibold">Time Remaining: {timeRemaining}</p>
        </div>
        <div className="mt-4">
          <button
            onClick={onClose}
            className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CouponPopup;

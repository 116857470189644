
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { axiosInstance } from '../../utils/axios';
import { FaInfoCircle } from 'react-icons/fa'; // Import an info icon

// Component for selecting multiple dealer categories with search functionality
const SelectDealerCategories = ({ onSelectionChange }) => {
  const [categories, setCategories] = useState([]); // State to store fetched categories dynamically
  const [selectedOptions, setSelectedOptions] = useState([]); // State to track selected options

  // Fetch categories with search functionality
  const fetchCategories = async (searchTerm) => {
    try {
      const response = await axiosInstance.get('/dealer-categories', {
        params: { search: searchTerm }, // Pass search term to backend API
      });
      console.log('categories search response:', response);
      setCategories(response.data.data);
    } catch (error) {
      console.error('Error fetching categories', error);
    }
  };

  useEffect(()=>{
    fetchCategories();
  },[])

  // Debounce the fetchCategories to prevent API calls on every keypress
  const debouncedFetchCategories = debounce((searchTerm) => {
    fetchCategories(searchTerm);
  }, 300);

  // Handle user input change to call search dynamically
  const handleInputChange = (inputValue) => {
    debouncedFetchCategories(inputValue);
  };

  // Handle changes when a user selects or deselects an option
  const handleChange = (selected) => {

    if (selected.length > 2) {
      return; // Ignore additional selections
    }
    console.log('selected categories list', selected);

    if (selected) {
      const selectedIds = selected.map(option => option.value);
      setSelectedOptions(selected);
      console.log('Extracted selected ids for categories', selectedIds);
      onSelectionChange(selectedIds);
    } else {
      setSelectedOptions([]);
      onSelectionChange([]); 
    }
  };

  return (
    <div className="my-2 mb-4">
        <div class="bg-blue-50 border-l-4 border-blue-500 text-blue-700 p-2 rounded-lg shadow-md flex items-center">
  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-blue-500 mr-3" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
    <path fill-rule="evenodd" d="M3 3a9 9 0 0118 0a9 9 0 11-18 0zm9 12a1 1 0 100-2 1 1 0 000 2zm0-6a1 1 0 100-2 1 1 0 000 2zm0-4a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
  </svg>
  <span class="text-sm">Tip:  If your category is not listed, please choose <strong>'Others'</strong>.</span>
</div>
      <label className="block text-gray-700 mb-2">Search & Select Dealer Categories</label>
      <Select
        options={categories.map(category => ({
          value: category.id,
          label: category.categoryName,
        }))}
        isMulti
        value={selectedOptions}
        onChange={handleChange}
        onInputChange={handleInputChange} // Trigger the search on input
        className="react-select"
        placeholder="Search categories..."
      />
    </div>
  );
};

export default SelectDealerCategories;

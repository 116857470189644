

import { Fragment, useEffect, useState } from "react";
import Banner from "../components/Banner/Banner";
import { Container } from "react-bootstrap";
import ShopList from "../components/ShopList";
import { useLocation, useParams } from "react-router-dom";
import ProductDetails from "../components/ProductDetails/ProductDetails";
import ProductReviews from "../components/ProductReviews/ProductReviews";
import useWindowScrollToTop from "../hooks/useWindowScrollToTop";
import { axiosInstance } from "../utils/axios";
import { getProductDetailsById } from "../utils/apiCall";


const Product = () => {

  const { id } = useParams(); 

  // alert(id)

  const location = useLocation(); // useLocation hook to access state passed via router
  const { productItem } = location.state || {}; // Destructure productItem from state
  // console.log('product item in product compoennt',productItem);

  // alert('ss')

  useEffect(()=>{
    getProductbyId();
  },[id])

  const getProductbyId = async()=>{
       try{
            let result = await getProductDetailsById(id);
            setSelectedProduct(result?.electronicUser)
            console.log('proudct by id is here',result)
       }catch(error){
         console.log('error in single product get ',error);
       }
  }

  console.log('product item',id)

  const [selectedProduct, setSelectedProduct] = useState(productItem);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [loading, setLoading] = useState(true); // to handle loading state
  const [error, setError] = useState(null); // to handle errors

  // useEffect(() => {
  //   if (selectedProduct) {
  //     window.scrollTo(0, 0);
  //     // Fetch related products based on the selected product's id
  //     const fetchRelatedProducts = async () => {
  //       try {
  //         setLoading(true);
  //         // API call to fetch related products based on the product category and id
  //         const response = await axiosInstance.get(`/products/related/${selectedProduct.id}`
  //         );
  //         console.log('resonse',response.data.relatedProducts)
  //         if (response && response.data.relatedProducts) {
  //           setRelatedProducts(response.data.relatedProducts);
  //         } else {
  //           setRelatedProducts([]); // No related products found
  //         }
  //       } catch (error) {
  //         setError(error.message); // Set error message if API call fails
  //       } finally {
  //         setLoading(false); // Turn off loading after the API call finishes
  //       }
  //     };

  //     fetchRelatedProducts();
  //   }
  // }, [selectedProduct]);

  useWindowScrollToTop();

  return (
    <Fragment >
      <div  style={{marginTop:50}}>
      {selectedProduct && (
        <>
          {/* <Banner title={selectedProduct.productName} /> */}
          <ProductDetails selectedProduct={selectedProduct} />
          <ProductReviews selectedProduct={selectedProduct} />

       
          {!loading && relatedProducts.length > 0 ? (
            <section className="related-products">
              <Container>
                <h3>You might also like</h3>
              </Container>
              <ShopList productItems={relatedProducts} />
            </section>
          ) : !loading && relatedProducts.length === 0 ? (
            <section className="related-products">
              <Container>
                <h3>No related products found</h3>
              </Container>
            </section>
          ) : (
            <section className="related-products">
              <Container>
                <h3>Loading related products...</h3>
              </Container>
            </section>
          )}
        </>
      )}

      {/* Display error message if the API call fails */}
      {/* {error && <div className="error">{error}</div>} */}
      </div>
    </Fragment>
  );
};

export default Product;

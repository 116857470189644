
// import { FaMapMarkerAlt } from "react-icons/fa";
// import { useSelector } from "react-redux";

// const ProductDetails = ({ selectedProduct }) => {

//   const buyer = useSelector((state) => state.buyer.buyer); // Access the buyer data from Redux

//   const {
//     productName,
//     price,
//     description,
//     electronicsUser,
//     createdAt,
//     updatedAt,
//     ProductCategory,
//     mrp,
//     mfgDate
//   } = selectedProduct;

//   console.log('selected product',selectedProduct);


//   const getGoogleMapsUrl = (latitude, longitude) => {
//     // Optionally, you can pass the user's location as origin if available.
//     const userLatitude = buyer?.latitude;
//     const userLongitude = buyer?.longitude;

//     if (userLatitude && userLongitude) {
//       return `https://www.google.com/maps/dir/?api=1&origin=${userLatitude},${userLongitude}&destination=${latitude},${longitude}`;
//     }

//     return `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
//   };

//   return (
//     <section className="py-8 bg-gray-100">
//       <div className="max-w-6xl mx-auto px-4 md:px-8">
//         {/* Product Info Section */}
//         <div className="bg-white shadow-md rounded-lg p-6 mb-6">
//           <div className="flex flex-col md:flex-row md:space-x-6">
//             {/* Product Image */}
//             <div className="w-full md:w-1/3 mb-4 md:mb-0">
//               <img
//                 loading="lazy"
//                 src={selectedProduct?.imageUri}
//                 alt={productName}
              
//                 className="w-full h-full object-cover rounded-lg shadow-sm"
//               />
//             </div>

//             {/* Product Information */}
//             <div className="flex-1">
//               <h2 className="text-2xl font-bold mb-2">{productName?.charAt(0).toUpperCase() + productName?.slice(1) || "Unknown product name"}</h2>
//               <p className="text-lg text-gray-700 mb-2">Price: {price}</p>
//               <p className="text-lg text-gray-700 mb-2">MRP: {mrp}</p>
//               <p className="text-sm text-gray-500 mb-2">
//                 Category: {selectedProduct?.ProductCategory?.categoryName || "Unknown"}
//               </p>
//              { selectedProduct?.mfgDate && <p className="text-sm text-gray-500 mb-2">
//                 Manufacturing Date: {new Date(selectedProduct?.mfgDate)?.toLocaleDateString()}
//               </p>}

              
//               <p className="text-gray-600 mb-4">Description{description}</p>
//             </div>
//           </div>
//         </div>

//         {/* Shop Info Section */}
//         <div className="bg-white shadow-md rounded-lg p-6">
//           <h3 className="text-lg font-semibold mb-3">Dealer Details</h3>
//           <div 
//           // className="flex items-center mb-4"
//           >
//             {/* <FaMapMarkerAlt className="text-red-500 text-xl" /> */}
//             <div>
//               <p className="text-sm font-semibold text-gray-700">
//                 Business Name : {electronicsUser?.shopName?.charAt(0).toUpperCase() + electronicsUser?.shopName.slice(1) || "Unknown Shop"}
//               </p>
//               <p className="text-sm text-gray-600">Communication Address : {electronicsUser?.shopAddress || "Unknown Address"}</p>
//               <p className="text-sm text-gray-600">Communication Area : {electronicsUser?.area || "Unknown Address"}</p>
//               <p className="text-sm text-gray-600">Contact Number : {electronicsUser?.mobile || "Unknown Address"}</p>
//             </div>

           
//           </div>
//           <a
//               href={getGoogleMapsUrl(electronicsUser?.latitude ,electronicsUser?.longitude)}
//               target="_blank"
//               rel="noopener noreferrer"
//               className="flex items-center text-blue-500"
//             >
//               <FaMapMarkerAlt className="mr-2" /> View on map
//             </a>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ProductDetails;











// import { FaMapMarkerAlt } from "react-icons/fa";
// import { useSelector } from "react-redux";

// const ProductDetails = ({ selectedProduct }) => {
//   const buyer = useSelector((state) => state.buyer.buyer); // Access the buyer data from Redux

//   const {
//     productName,
//     price,
//     description,
//     electronicsUser,
//     createdAt,
//     updatedAt,
//     ProductCategory,
//     mrp,
//     mfgDate
//   } = selectedProduct;

//   console.log('selected product', selectedProduct);

//   const getGoogleMapsUrl = (latitude, longitude) => {
//     const userLatitude = buyer?.latitude;
//     const userLongitude = buyer?.longitude;

//     if (userLatitude && userLongitude) {
//       return `https://www.google.com/maps/dir/?api=1&origin=${userLatitude},${userLongitude}&destination=${latitude},${longitude}`;
//     }

//     return `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
//   };

//   return (
//     <section className="py-8 bg-gray-100">
//       <div className="max-w-6xl mx-auto px-4 md:px-8">
//         {/* Product Info Section */}
//         <div className="bg-white shadow-md rounded-lg p-6 mb-6">
//           <div className="flex flex-col md:flex-row md:space-x-6">
//             {/* Product Image */}
//             <div className="w-full md:w-1/3 mb-4 md:mb-0">
//               <img
//                 // loading="lazy"
//                 src={selectedProduct?.imageUri}
//                 alt={productName}
//                 className="w-full h-full object-cover rounded-lg shadow-sm"
//               />
//             </div>

//             {/* Product Information */}
//             <div className="flex-1">
//               <h2 className="text-2xl font-bold mb-2">{productName?.charAt(0).toUpperCase() + productName?.slice(1) || "Unknown product name"}</h2>
//               <p className="text-lg text-gray-700 mb-2">Price: {price}</p>
//               <p className="text-lg text-gray-700 mb-2">MRP: {mrp}</p>
//               <p className="text-sm text-gray-500 mb-2">
//                 Category: {selectedProduct?.ProductCategory?.categoryName || "Unknown"}
//               </p>
//               { selectedProduct?.mfgDate && <p className="text-sm text-gray-500 mb-2">
//                 Manufacturing Date: {new Date(selectedProduct?.mfgDate)?.toLocaleDateString()}
//               </p>}

//               <p className="text-gray-600 mb-4">Description: {description}</p>
//             </div>
//           </div>
//         </div>

//         {/* Shop Info Section */}
//         <div className="bg-white shadow-md rounded-lg p-6 mb-6">
//           <h3 className="text-lg font-semibold mb-3">Dealer Details</h3>
//           <div>
//             <p className="text-sm font-semibold text-gray-700">
//               Business Name : {electronicsUser?.shopName?.charAt(0).toUpperCase() + electronicsUser?.shopName.slice(1) || "Unknown Shop"}
//             </p>
//             <p className="text-sm text-gray-600">Communication Address : {electronicsUser?.shopAddress || "Unknown Address"}</p>
//             <p className="text-sm text-gray-600">Communication Area : {electronicsUser?.area || "Unknown Address"}</p>
//             <p className="text-sm text-gray-600">Contact Number : {electronicsUser?.mobile || "Unknown Address"}</p>
//           </div>
          
//           <a
//             href={getGoogleMapsUrl(electronicsUser?.latitude, electronicsUser?.longitude)}
//             target="_blank"
//             rel="noopener noreferrer"
//             className="flex items-center text-blue-500 mt-4"
//           >
//             <FaMapMarkerAlt className="mr-2" /> View on map
//           </a>
//         </div>

//         {/* Shop Photos Section */}
//         <div className="bg-white shadow-md rounded-lg p-6">
//           <h3 className="text-lg font-semibold mb-3">Shop Photos</h3>
//           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
//             {electronicsUser?.photos?.map((photo, index) => (
//               <div key={index} className="relative">
//                 <img
//                   // loading="lazy"
//                   src={photo?.photoUrl}
//                   alt={`Shop Photo ${index + 1}`}
//                   className="w-full h-48 object-cover rounded-lg shadow-sm"
//                 />
//                 {photo?.description && (
//                   <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-sm p-2 rounded-b-lg">
//                     {photo.description}
//                   </div>
//                 )}
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ProductDetails;







import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { useSelector } from "react-redux";

const ProductDetails = ({ selectedProduct }) => {

  console.log('selected product',selectedProduct)

  const buyer = useSelector((state) => state.buyer.buyer); // Access the buyer data from Redux

  const {
    shopName,
    price,
    description,
    electronicsUser,
    createdAt,
    updatedAt,
    ProductCategory,
    mrp,
    mfgDate
  } = selectedProduct;

  console.log('selected product', selectedProduct);

  const getGoogleMapsUrl = (latitude, longitude) => {
    const userLatitude = buyer?.latitude;
    const userLongitude = buyer?.longitude;

    if (userLatitude && userLongitude) {
      return `https://www.google.com/maps/dir/?api=1&origin=${userLatitude},${userLongitude}&destination=${latitude},${longitude}`;
    }

    return `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
  };

  return (
    <section className="py-8 bg-gray-100">
      <div className="max-w-6xl mx-auto px-4 md:px-8">
        {/* Product Info Section */}
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
          <div className="flex flex-col md:flex-row md:space-x-6">
            {/* Product Image */}
            <div className="w-full md:w-1/3 mb-4 md:mb-0">
              <img
                src={selectedProduct?.imageUri}
                alt={selectedProduct?.shopName}
                className="w-full h-full object-cover rounded-lg shadow-sm"
              />
            </div>

            {/* Product Information */}
            <div className="flex-1">
              {/* <h2 className="text-2xl font-bold mb-2">{productName?.charAt(0).toUpperCase() + productName?.slice(1) || "Unknown product name"}</h2> */}
              <p className="text-lg text-gray-700 mb-2">Price: {price}</p>
              <p className="text-lg text-gray-700 mb-2">MRP: {mrp}</p>
              <p className="text-sm text-gray-500 mb-2">
                Category: {selectedProduct?.shopName || "Unknown"}
              </p>
              {/* { selectedProduct?.mfgDate && <p className="text-sm text-gray-500 mb-2">
                Manufacturing Date: {new Date(selectedProduct?.mfgDate)?.toLocaleDateString()}
              </p>} */}

              {/* <p className="text-gray-600 mb-4">Description: {description}</p> */}
            </div>
          </div>
        </div>

        {/* Shop Info Section */}
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h3 className="text-lg font-semibold mb-3">Dealer Details</h3>
          <div>
            <p className="text-sm font-semibold text-gray-700">
              Business Name : {selectedProduct?.shopName?.charAt(0).toUpperCase() + selectedProduct?.shopName.slice(1) || "Unknown Shop"}
            </p>
            <p className="text-sm text-gray-600">Communication Address : {selectedProduct?.shopAddress || "Unknown Address"}</p>
            <p className="text-sm text-gray-600">Communication Area : {selectedProduct?.area || "Unknown Address"}</p>
            {/* <p className="text-sm text-gray-600">Contact Number : {electronicsUser?.mobile || "Unknown Address"}</p> */}
              <a
              href={`tel:${selectedProduct?.mobile}`}
              className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white py-1 px-1 mt-2 rounded-md transition-all duration-200"
            >
              <FaPhoneAlt className="mr-2" /> Contact
            </a>
          </div>
          {selectedProduct?.userLocationUrl ? (
        <a
          href={selectedProduct?.userLocationUrl}  // Open the Google Maps URL provided in the userLocationUrl
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center text-blue-500"
        >
          <FaMapMarkerAlt className="mr-2" /> Location
        </a>
      ) : (
          <a
            href={getGoogleMapsUrl(selectedProduct?.latitude, selectedProduct?.longitude)}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-blue-500 mt-4"
          >
            <FaMapMarkerAlt className="mr-2" /> View on map
          </a>
      )}

        </div>

        {/* Shop Photos Section */}
        <div className="bg-white shadow-md rounded-lg p-6">
          <h3 className="text-lg font-semibold mb-3">Shop Photos</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {selectedProduct?.photos?.map((photo, index) => (
              <div key={index} className="relative">
                <img
                  src={photo?.photoUrl} 
                  alt={`Shop Photo ${index + 1}`}
                  className="w-full h-48 object-cover rounded-lg shadow-sm"
                />
                {photo?.description && (
                  <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-sm p-2 rounded-b-lg">
                    {photo.description}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductDetails;


import { axiosInstance } from "./axios";

export const getSingleAdminDetails = async () => {
    const response = await axiosInstance.get(`/admin/get/by/token`);
    return response.data;
  };

  export const isDealerExistCheckFunction = async (mobile) => {
    try {
      const response = await axiosInstance.post("/dealer/registered", { mobile: mobile });
      return response.data;
    } catch (error) {
      // Check for an HTTP error and provide specific details
      if (error.response) {
        // Server responded with a status code other than 2xx
        const errorMessage = error.response.data?.message || "An error occurred while checking dealer registration.";
        alert(`Server Error: ${errorMessage}`);
      } else if (error.request) {
        // Request was made but no response received
        alert("Network Error: No response received from the server. Please check your connection.");
      } else {
        // Other errors (e.g., programming errors, configuration issues)
        alert(`Unexpected Error: ${error.message}`);
      }
      console.error("Error details:", error);
    }
  };
  
  

  export const getSingleBuyerDetails = async () => {
    const response = await axiosInstance.get(`/buyer/get/by/token`);
    return response.data;
  };


  export const getProductDetailsById = async (id) => {
    try{
    const response = await axiosInstance.get(`/products/${id}`);
    return response.data;
    }catch(err){
           console.log('err in single product get ',err)
    }
  };

  export const generateCoupon = async (discountId) => {
      try {
        const response = await axiosInstance.post('/coupon', {
          discountId: discountId, // Send discount ID from the product item
        });
        return response
      } catch (error) {
        console.error("Error generating coupon:", error);
        alert("Failed to generate coupon. Please try again.");
      } 
    };
  

  export const updateSingleBuyerDetails = async (editableProduct) => {
    const response = await axiosInstance.put(`/buyer/update/by/token`, editableProduct, {
      headers: {
        "Content-Type": "multipart/form-data", // Use multipart/form-data for file upload
      },
    });
    return response.data;
  };
  

  export const updateSingleAdminDetails = async (editableProduct) => {
    const response = await axiosInstance.put(`/admin/update/by/token`, editableProduct, {
      headers: {
        "Content-Type": "multipart/form-data", // Use multipart/form-data for file upload
      },
    });
    return response.data;
  };
  

  

  export const getAllAdvertisement = async () => {
    try{
    const response = await axiosInstance.get(`/advertisements`);
    return response.data;
    }catch(err){
        console.log('error is here',err)
    }
  };

  

  export const storeImpression = async (deviceInfo,adId) => {
    try {
    const  response = await axiosInstance.post(`/track/advertisement/${adId}`, { 
      deviceInfo: JSON.stringify(deviceInfo) 
    });
    return response.data;
    } catch (error) {
      console.error("Failed to send OTP:", error);
    }
  };




  export const sendOtp = async (phoneNumber) => {
      if (!phoneNumber) return alert("Enter a valid phone number");
      try {
      const  response = await axiosInstance.post("/otp/send-otp", { 
        contactNumber: phoneNumber 
      });
      return response;
      } catch (error) {
        console.error("Failed to send OTP:", error);
        alert("Failed to send OTP");
      }
    };


  export const verifyOtp = async (phoneNumber,otp) => {
        try {
          const response = await axiosInstance.post("/otp/verify-otp", {
            contactNumber: phoneNumber,
            otp,
          });
            return response;
        } catch (error) {
          // console.error("Verification failed:", error);
          // alert("OTP verification failed");
        }
      };


  

      export  const requestLocation = () => {
        return new Promise((resolve, reject) => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => resolve(position),
              (error) => reject(error),
              {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0,
              }
            );
          } else {
            reject("Geolocation is not supported by this browser.");
          }
        });
      };

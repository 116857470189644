// import React, { useState, useEffect } from "react";

// const OTPVerification = ({ isOpen, onClose }) => {
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [otp, setOtp] = useState("");
//   const [showOtpInput, setShowOtpInput] = useState(false);
//   const [timer, setTimer] = useState(120); // 2 minutes in seconds
//   const [isResendActive, setIsResendActive] = useState(false);

//   useEffect(() => {
//     if (timer > 0 && showOtpInput) {
//       const countdown = setInterval(() => {
//         setTimer((prev) => prev - 1);
//       }, 1000);
//       return () => clearInterval(countdown);
//     } else if (timer === 0) {
//       setIsResendActive(true);
//     }
//   }, [timer, showOtpInput]);

//   const handleSendOtp = () => {
//     setShowOtpInput(true);
//     setTimer(120);
//     setIsResendActive(false);
//     console.log("Sending OTP to", phoneNumber);
//   };

//   const handleResendOtp = () => {
//     setTimer(120);
//     setIsResendActive(false);
//     console.log("Resending OTP to", phoneNumber);
//   };

//   const handleVerifyOtp = () => {
//     console.log("Verifying OTP:", otp);
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
//       <div className="w-80 p-6 bg-white shadow-md rounded-lg">
//         <button
//           onClick={onClose}
//           className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
//         >
//           ✖
//         </button>
//         <h2 className="text-xl font-bold text-center mb-4">
//           {showOtpInput ? "Enter OTP" : "Verify Phone Number"}
//         </h2>

//         {!showOtpInput ? (
//           <div className="space-y-4">
//             <input
//               type="text"
//               placeholder="Enter phone number"
//               value={phoneNumber}
//               onChange={(e) => setPhoneNumber(e.target.value)}
//               className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
//             />
//             <button
//               onClick={handleSendOtp}
//               className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600"
//             >
//               Send SMS with code
//             </button>
//           </div>
//         ) : (
//           <div className="space-y-4">
//             <input
//               type="text"
//               placeholder="Enter OTP"
//               value={otp}
//               onChange={(e) => setOtp(e.target.value)}
//               className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
//             />
//             <button
//               onClick={handleVerifyOtp}
//               className="w-full bg-green-500 text-white py-2 rounded-md hover:bg-green-600"
//             >
//               Verify OTP
//             </button>
//             <div className="flex justify-between items-center">
//               <button
//                 onClick={handleResendOtp}
//                 disabled={!isResendActive}
//                 className={`text-sm ${
//                   isResendActive
//                     ? "text-blue-500 hover:underline"
//                     : "text-gray-400 cursor-not-allowed"
//                 }`}
//               >
//                 Resend OTP
//               </button>
//               <span className="text-sm text-gray-500">
//                 {isResendActive
//                   ? "You can resend now"
//                   : `Resend in ${Math.floor(timer / 60)}:${
//                       timer % 60 < 10 ? "0" : ""
//                     }${timer % 60}`}
//               </span>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };


// export default OTPVerification;



import React, { useState, useEffect } from "react";
import axios from "axios";
import { setBuyer } from "../../app/features/login/buyerSlice";
import { setToken } from "../../app/features/login/authSlice";
import { useDispatch } from "react-redux";
import { axiosInstance } from "../../utils/axios";
import { sendOtp, verifyOtp } from "../../utils/apiCall";

const OTPVerification = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [timer, setTimer] = useState(120);
  const [isResendActive, setIsResendActive] = useState(false);

  useEffect(() => {
    let countdown;
    if (showOtpInput && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsResendActive(true);
    }

    return () => clearInterval(countdown);
  }, [showOtpInput, timer]);

  const handleSendOtp = async () => {
    if (!phoneNumber) return alert("Enter a valid phone number");
    try {
      const  response =  await sendOtp(phoneNumber);
       console.log('response',response);
      setShowOtpInput(true);
      setTimer(120);
      setIsResendActive(false);
      alert("OTP sent successfully");
    } catch (error) {
      console.error("Failed to send OTP:", error);
      alert("Failed to send OTP");
    }
  };

  const handleResendOtp = async () => {
    if (!isResendActive) return alert("Wait for the timer to expire");
    try {
      const  response = await sendOtp(phoneNumber);
      setTimer(120);
      setIsResendActive(false);
      alert("OTP resent successfully");
    } catch (error) {
      console.error("Resend failed:", error);
      alert("Failed to resend OTP");
    }
  };

  const handleVerifyOtp = async () => {
    try {
       const response = await verifyOtp(phoneNumber,otp)
      if (response) {
        const buyerResponse = await axiosInstance.post("/buyers", {
          mobile : phoneNumber,
        });
        setToken(buyerResponse.data.token); // Save the token (or save to localStorage)
        localStorage.setItem('token', buyerResponse.data.token);
        localStorage.setItem('buyer','buyer');
        dispatch(setBuyer('buyer'));
        dispatch(setToken(buyerResponse.data.token));
        // alert(buyerResponse.data.message);
        onClose();
      } else {
        alert("Incorrect OTP");
      }
    } catch (error) {
      console.error("Verification failed:", error);
      alert("OTP verification failed");
    }
  };

  if (!isOpen) return null;

  return (


    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
  <div className="relative w-80 p-6 bg-white shadow-md rounded-lg z-50">
    {/* Set z-index to ensure it's clickable */}
    <button
      style={{color:'black',}}
      onClick={onClose}
      className="absolute top-2 right-2 text-black hover:text-gray-800 z-50"
    >
      X
    </button>
    <h2 className="text-xl font-bold text-center mb-4">
      {showOtpInput ? "Enter OTP" : "Login To Continue"}
    </h2>
    {!showOtpInput ? (
      <div className="space-y-4">
        <input
          type="text"
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
        <button
          onClick={handleSendOtp}
          className="w-full bg-[#e2f2b2] hover:text-white py-2 rounded-md hover:bg-[#4ED31A]"
        >
          Get OTP
        </button>
      </div>
    ) : (
      <div className="space-y-4">
        <input
          type="text"
          placeholder="Enter OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
        <button
          onClick={handleVerifyOtp}
          className="w-full bg-[#e2f2b2] hover:text-white py-2 rounded-md hover:bg-green-600"
        >
          Verify OTP
        </button>
      </div>
    )}
  </div>
</div>

  );
};

export default OTPVerification;
